import React from 'react';
import { FindAllFrameCompaniesResponse } from '@ansvar-sakerhet/api-client';
import { useTable } from 'react-table';
import { S } from './FrameCompaniesTable.style';
import { useFrameCompaniesTableData } from './hooks/useFrameCompaniesTableData';

export const FrameCompaniesTable = ({
  frameCompanies,
}: {
  frameCompanies: FindAllFrameCompaniesResponse[];
}) => {
  const data = useFrameCompaniesTableData(frameCompanies);
  const table = useTable(data);

  if (frameCompanies.length === 0) {
    return <S.B1>Det finns inga ramavtalsföretag att visa.</S.B1>;
  }

  return (
    <S.TableContainer>
      <S.AltTable {...table.getTableProps()}>
        <S.AltTHead>
          {table.headerGroups.map((headerGroup) => (
            <S.AltTHeadRow
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              {headerGroup.headers.map((column) => {
                return (
                  <S.AltTHeadCell
                    {...column.getHeaderProps()}
                    key={column.getHeaderProps().key}
                  >
                    <S.TableText>{column.render('Header')}</S.TableText>
                  </S.AltTHeadCell>
                );
              })}
            </S.AltTHeadRow>
          ))}
        </S.AltTHead>

        <S.AltTBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);

            return (
              <S.AltTBodyRow {...row.getRowProps()} key={row.getRowProps().key}>
                {row.cells.map((cell) => {
                  return (
                    <S.AltTBodyCell
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                    >
                      {cell.render('Cell')}
                    </S.AltTBodyCell>
                  );
                })}
              </S.AltTBodyRow>
            );
          })}
        </S.AltTBody>
      </S.AltTable>
    </S.TableContainer>
  );
};
