import React, { useEffect, useState } from 'react';
import {
  SortPersonnel,
  useGetAllPersonnel,
} from '../../../../traits/api/workRegisters/useGetAllPersonnel';
import { useControl } from '../../components/ControlLayout';
import { Loading } from '../../../../components/Loading';
import { Error } from '../../../../components/Error';
import { WorkRegisterTable } from './components/WorkRegisterTable';
import { Images } from '@ansvar-sakerhet/ui/src/assets/images';
import { useNavigate, useParams } from 'react-router-dom';

import { S } from './WorkRegister.styles';
import { WorkRegisterSortDropdown } from './components/WorkRegisterSort/WorkRegisterSortDropdown';

export const WorkRegister: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(true);
  const control = useControl();
  const [sort, setSort] = useState<SortPersonnel>(
    (localStorage.getItem(
      'sort-' + control.data?.controlId
    ) as SortPersonnel) || 'firstName_ASC'
  );

  const [page, setPage] = useState(
    Number(localStorage.getItem(control.data?.controlId))
  );
  const [showExtra, setShowExtra] = useState(false);

  const personnel = useGetAllPersonnel(
    control.data?.controlId,
    control.data?.workRegister?.workRegisterId,
    page,
    sort
  );

  useEffect(() => {
    if (personnel.isSuccess) {
      setIsLoading(false);
    }
  }, [personnel.data]);
  const { state } = useParams();
  React.useEffect(() => {
    const onScroll = () => {
      localStorage.setItem('Workregister', window.scrollY + '');
    };

    let scroll = Number(localStorage.getItem('Workregister'));
    if (!scroll) {
      scroll = 0;
    }
    new Promise((r) => setTimeout(r, 200)).then(() => {
      window.scrollTo({
        top: scroll,
        left: 0,
      });
    });

    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const navigateToCoverPage = () => {
    window.scrollTo(0, 0);
    navigate(
      `/kontroller/${state}/${control.data.inspectionId}/underentreprenorer`
    );
  };

  if (personnel.isLoading || loading) {
    return <Loading />;
  }

  return (
    <S.WorkRegisterContainer>
      {!control.data.gdprDataDeletedAt ? (
        <S.TableContainer>
          {personnel.isLoading && <Loading />}

          {personnel.isError && <Error refetch={personnel.refetch} />}

          {personnel.isSuccess && (
            <React.Fragment>
              <S.Margin $spacing="xs" />

              <S.B3 $color="darkGray">{personnel.count} st</S.B3>
              <div>
                <WorkRegisterSortDropdown
                  sort={sort}
                  setSort={(sort: SortPersonnel) => {
                    localStorage.setItem(
                      'sort-' + control.data?.controlId,
                      sort
                    );
                    setSort(sort);
                  }}
                />
                <S.Button
                  $outlined={showExtra}
                  onClick={() => {
                    setShowExtra(!showExtra);
                  }}
                >
                  {showExtra ? 'Dölj' : 'Visa'}
                </S.Button>
              </div>

              <WorkRegisterTable
                data={personnel.data}
                loading={personnel.isLoading}
                page={page}
                setPage={(e: number) => {
                  setIsLoading(true);
                  personnel.remove();
                  localStorage.setItem(control.data?.controlId, e.toString());
                  setPage(e);
                }}
                showExtra={showExtra}
                numberOfPages={Math.ceil(personnel.count / 20)}
              />
            </React.Fragment>
          )}
        </S.TableContainer>
      ) : (
        <S.EmptyContainer>
          <img
            src={Images.Empty}
            width={279}
            height={204}
            alt="no content available"
          />

          <S.TextContainer>
            <S.H5>Här var det tomt!</S.H5>

            <S.B2>
              Det kan bero på att datan har raderats i ett tidigare skede
            </S.B2>
          </S.TextContainer>
        </S.EmptyContainer>
      )}

      <S.Footer>
        <S.Button $width={289} onClick={navigateToCoverPage}>
          Nästa
        </S.Button>
      </S.Footer>
    </S.WorkRegisterContainer>
  );
};
