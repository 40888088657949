import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useControl,
  useResultFormContext,
} from '../../components/ControlLayout/ControlLayout';
import { Rules } from './components/Rules';
import { useGetControlFiles } from '../../../../traits/api/controls/useGetControlFiles';
import { Photos } from './components/Photos';
import { Textarea } from './components/Textarea';
import { AccordionHeader } from './components/AccordionHeader';
import { S } from './ResultAndActions.styles';
import { RadioInput } from '../../../../components/RadioInput';

export const ResultAndActions: React.FC = () => {
  const navigate = useNavigate();
  const { data } = useControl();
  const { trigger, watch, setValue, control } = useResultFormContext();

  const files = useGetControlFiles(data.inspectionId, data.controlId);

  const shellProtectionFile = files.data
    ?.filter((file) => file.type === 'ShellProtection')
    .filter((file) => !watch('deletedFiles').includes(file.controlFileId));

  const workEnvironmentFile = files.data
    ?.filter((file) => file.type === 'WorkEnvironment')
    .filter((file) => !watch('deletedFiles').includes(file.controlFileId));
  const { state } = useParams();
  const navigateToDeviation = async () => {
    const isValid = await trigger();
    if (!isValid) return;
    navigate(`/kontroller/${state}/${data.inspectionId}/avvikelser`);
  };

  const navigateToSummary = async () => {
    navigate(`/kontroller/${state}/${data.inspectionId}/bedomning`);
  };

  React.useEffect(() => {
    const onScroll = () => {
      localStorage.setItem('RAA', window.scrollY + '');
    };

    let scroll = Number(localStorage.getItem('RAA'));
    if (!scroll) {
      scroll = 0;
    }
    new Promise((r) => setTimeout(r, 200)).then(() => {
      window.scrollTo({
        top: scroll,
        left: 0,
      });
    });

    window.removeEventListener('s croll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const generateConclution = () => {
    const res = `
    <div>
    <div><b>Positiva faktorer</b> <br></div>
    <p />
    <p />
    <ul><li>Samtliga individer hade giltig legitimation på arbetsplatsen.</li><li>Samtliga individer hade ett aktivt och giltigt ID06-kort och har utfört den obligatoriska utbildningen Safe Construction Training.</li><li>Samtliga individer hade rätt att arbeta i Sverige.</li><li>Det konstaterades god ordning och reda på ställningarna.</li><li>Arbetsområdet konstaterades vara tillräckligt avgränsat med väl synlig avgränsning.</li><li>Det fanns ingen antydan att någon individ var påverkad av alkohol eller droger.</li><li>Inga avvikelser relaterade till diskrimineringsrisker kunde påvisas.</li><li>Det konstaterades ett stort engagemang för förbättringsarbete och hantering av åtgärder från biträdande platschef för projektet.</li></ul>
    <div>
      <br>
    </div>

 <div> <b>Konstaterade brister med rekommenderad åtgärd</b> <br> </div>
 <p />
 <p />
 <ul><li>2 av 8 individer var ej inloggade i den elektroniska personalliggaren under kontrolltillfället.</li><li>4 av 8 individer saknade delar av eller hela personliga skyddsutrustningen som var kravställd på arbetsplatsen.</li><li>Det konstaterades brister med brandsläckare som ej var servade det senaste året.</li><li>2 av 6 bolag återfanns ej i den föranmälda och godkända UE-listan.</li></ul>
   <div>
      <br>
    </div>
   </div>
  `;

    setValue('summaryAndRecommendations', res);
  };

  return (
    <S.ResultContainer>
      <S.ResultInner
        type="multiple"
        paddingbottom={72}
        defaultValue={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']}
      >
        <S.H4>Resultat/åtgärder</S.H4>
        <S.Body value="1">
          <S.Header>
            <S.Icon src="Edit" />
            <S.H6>Sammanfattning av resultat</S.H6>
          </S.Header>

          <S.BodyInner>
            <Textarea
              placeholder="Sammanfatta och skriv eventuella rekommendationer här..."
              name="summaryAndRecommendations"
              label="Sammanfattning"
              showEditor={true}
            />
            <S.Button $outlined onClick={generateConclution}>
              Generera sammanfattning
            </S.Button>
          </S.BodyInner>
        </S.Body>
        <S.Body value="3">
          <AccordionHeader title="Elektronisk personalliggare (EPL) – Skatteförfarandelagen" />

          <S.BodyInner>
            <Textarea
              label="Resultat"
              name="workRegisterResult"
              placeholder="Beskriv resultatet..."
            />

            <RadioInput
              state={watch('workRegisterActionsNeeded')}
              control={control}
              question="Åtgärder behövs"
              options={[
                { value: 'no', label: 'Nej' },
                { value: 'yes', label: 'Ja' },
              ]}
              name="workRegisterActionsNeeded"
            />

            <Textarea
              label="Åtgärder"
              name="workRegisterActions"
              placeholder="Beskriv åtgärder som gjorts..."
            />

            <Rules rule="Personalliggare på en byggarbetsplats skall föras elektroniskt. Detta enligt 39 kap. 11a § Skatteförfarandelagen. Byggherre är i huvudregeln ansvarig att tillhandahålla utrustning för personalliggare, dock finns det vissa undantag från denna skyldighet. Den som bedriver byggverksamhet på en byggarbetsplats där byggherren tillhandahållit utrustning ska föra en elektronisk personalliggare. Regler om personalliggarens form, dess innehåll och hur den ska föras framgår av Skatteverkets föreskriver om personalliggare (SKVFS 2015:6). Av Skatteförfarandelagen framgår även att personalliggaren skall hållas tillgänglig för Skatteverket och byggherren på byggarbetsplatsen (39kap. 12 § andra stycket Skatteförfarandelagen). Om entreprenören inte fullgör sina skyldigheter att föra personalliggare finns det lagstadgade föreläggande med vite som sanktioner (44 kap. 1§ Skatteförfarandelagen). " />
          </S.BodyInner>
        </S.Body>

        <S.Body value="5">
          <AccordionHeader title="Brott mot ID06 regelverk" />

          <S.BodyInner>
            <Textarea
              label="Resultat"
              name="id06DeviationsResult"
              placeholder="Beskriv resultatet..."
            />

            <RadioInput
              state={watch('id06DeviationsActionsNeeded')}
              control={control}
              question="Åtgärder behövs"
              options={[
                { value: 'no', label: 'Nej' },
                { value: 'yes', label: 'Ja' },
              ]}
              name="id06DeviationsActionsNeeded"
            />

            <Textarea
              label="Åtgärder"
              name="id06DeviationsActions"
              placeholder="Beskriv åtgärder som gjorts..."
            />

            <Rules
              rule="ID06 används för närvaroregistrering av personer i arbetsplatsens personalliggare, digitalisering av arbetares utbildningsbevis och inpasserings- och låsfunktioner i lokala system. I enlighet med ID06 Allmänna bestämmelser ska användarföretag se till att anställda innehar giltigt ID06-kort vilket ska bäras synligt. Vidare ska anställda på anmodan kunna uppvisa giltig legitimation (pass eller legitimationshandling med fotografi utfärdat av en myndighet).  Ifall det upptäcks felaktigheter eller brister som påverkar hantering av ID06-system skall detta utan dröjsmål underrättas för en ansvarige. Om på arbetsplatsen upptäcks personer som inte uppfyller krav i enlighet med de allmänna bestämmelserna för ID06 kan dessa personer avvisas från arbetsplatsen.
Användarföretag har rätt att beställa och registrera ID06-kort till anställda, för vilka användarföretaget betalar lön och lagstadgade arbetsgivaravgifter (i EU-direktiv benämnt socialavgifter)."
            />
          </S.BodyInner>
        </S.Body>

        <S.Body value="6">
          <AccordionHeader title="Avsaknad av giltig legitimation" />

          <S.BodyInner>
            <Textarea
              label="Resultat"
              name="identificationResult"
              placeholder="Beskriv resultatet..."
            />

            <RadioInput
              state={watch('identificationActionsNeeded')}
              control={control}
              question="Åtgärder behövs"
              options={[
                { value: 'no', label: 'Nej' },
                { value: 'yes', label: 'Ja' },
              ]}
              name="identificationActionsNeeded"
            />

            <Textarea
              label="Åtgärder"
              name="identificationActions"
              placeholder="Beskriv åtgärder som gjorts..."
            />

            <Rules rule="I enlighet med ID06 Allmänna bestämmelser ska användarföretag se till att anställda innehar giltigt ID06-kort vilket ska bäras synligt. Vidare ska anställda på anmodan kunna uppvisa giltig legitimation (pass eller legitimationshandling med fotografi utfärdat av en myndighet).  Ifall det upptäcks felaktigheter eller brister som påverkar hantering av ID06-system skall detta utan dröjsmål underrättas för en ansvarige. Om på arbetsplatsen upptäcks personer som inte uppfyller krav i enlighet med de allmänna bestämmelserna för ID06 kan dessa personer avvisas från arbetsplatsen." />
          </S.BodyInner>
        </S.Body>

        <S.Body value="7">
          <AccordionHeader title="Kontroll av arbetstillstånd och uppehållshandlingar" />

          <S.BodyInner>
            <Textarea
              label="Resultat"
              name="workPermitResult"
              placeholder="Beskriv resultatet..."
            />

            <RadioInput
              state={watch('workPermitActionsNeeded')}
              control={control}
              question="Åtgärder behövs"
              options={[
                { value: 'no', label: 'Nej' },
                { value: 'yes', label: 'Ja' },
              ]}
              name="workPermitActionsNeeded"
            />

            <Textarea
              label="Åtgärder"
              name="workPermitActions"
              placeholder="Beskriv åtgärder som gjorts..."
            />

            <Rules
              rule="Arbetstillstånd är ett tillstånd att arbeta i Sverige. En utlänning som skall arbeta i Sverige på grund av anställning här eller utomlands skall ha arbetstillstånd. Arbetstillstånd får ges till en utlänning utanför EU som har erbjudits en anställning enligt 6 kap. 2 § Utlänningslagen. Arbetstillstånd skall alltid knytas till en viss arbetsgivare eller avse ett visst slag av arbete. Det är arbetsgivarens ansvar att kontrollera att personen som anlitas från ett land utanför EU har rätt att vara och arbeta i Sverige. Det är även arbetsgivarens skyldighet att underrätta Skatteverket om anställningen. Den som medvetet eller av oaktsamhet anställer person som inte har rätt att vara eller arbeta i Sverige kan få betala straffavgifter (20 kap. 12 § Utlänningslag (2005:716)), fråntas rätten till offentliga stöd och förmåner under fem år, bli återbetalningsskyldig till tidigare utbetalda bidrag och/eller dömas till böter eller fängelse enligt 20 kap. 5 § Utlänningslagen (2005:716). 
              Undantag för specialistkunskap: Av 5 kap. 2 § p 10 utlänningsförordningen framgår att en utlänning som har specialistuppgifter inom en internationell koncern och som i denna egenskap tillfälligt arbetar i Sverige är undantagen kravet på arbetstillstånd för en sammanlagd tid som understiger ett år. Detta undantag kan användas enbart en gång, det vill säga som en längre period som är upp till ett år eller i ett antal kortare perioder som sammanlagt understiger ett år. Har personen arbetat i Sverige i en sammanlagd tid som understiger ett år är det kravet på arbetstillstånd som aktualiseras därefter. Undantaget förnyas inte, såsom är fallet i ex de undantag som gäller ett antal månader från inresan eller inom ett år. När det gäller begreppet specialist kan en viss vägledning tas från ICT-lagstiftningen (6 b kap. utlänningslagen). Personen som åberopar undantaget för specialister ska kunna uppvisa att han eller hon har de yrkeskvalifikationer och den erfarenhet eller utbildning som krävs för uppdraget som specialist. Man kan säga att personen som åberopar undantaget bör kunna visa unik kompetens och erfarenhet, att personen inte lätt går att ersätta, personen har en förhållandevis hög position inom koncernen. Även lönens och andra ersättningars storlek bör beaktas."
            />
          </S.BodyInner>
        </S.Body>

        <S.Body value="8">
          <AccordionHeader title="Utstationeringsregelverket" />

          <S.BodyInner>
            <Textarea
              label="Resultat"
              name="secondmentRegulationsResult"
              placeholder="Beskriv resultatet..."
            />

            <RadioInput
              state={watch('secondmentRegulationsActionsNeeded')}
              control={control}
              question="Åtgärder behövs"
              options={[
                { value: 'no', label: 'Nej' },
                { value: 'yes', label: 'Ja' },
              ]}
              name="secondmentRegulationsActionsNeeded"
            />

            <Textarea
              label="Åtgärder"
              name="secondmentRegulationsActions"
              placeholder="Beskriv åtgärder som gjorts..."
            />

            <Rules
              rule="Utstationerade arbetstagare garanteras ett visst skydd enligt bestämmelser i EU:s utstationeringsdirektiv (Europaparlamentets och rådets direktiv 96/71/EG av den 16 
              december 1996 om utstationering av arbetstagare i samband med tillhandahållande av tjänster). 
              Utstationeringsregler innebär att utländska arbetsgivare som utstationerar arbetstagare till 
              Sverige ska anmäla arbetstagarna och en kontaktperson (29 § lagen (1999:678) om utstationering av arbetstagare). Om arbetsgivare brister i sina skyldigheter och inte anmäler en utstationering bryter mot regelverket. I konsekvenser kan detta leda till att arbetsgivare få betala sanktionsavgift som är 20 000 kronor (12 § Förordning (2017:319) om utstationering av arbetstagare). Detsamma gäller om kontaktperson inte registreras eller om dokumentation om anmälan inte lämnas till tjänstemottagaren i tid. 
              Om tjänstemottagaren inte får dokumentation om anmälan ska tjänstemottagaren underrätta Arbetsmiljöverket senast tre dagar efter det att arbetet har påbörjats (31 § lagen (1999:678) om utstationering av arbetstagare). Ifall tjänstemottagare har överträtt bestämmelsen om underrättelseskyldighet ska en sanktionsavgift tas ut enligt 37 § andra stycket lagen (1999:678) om utstationering av arbetstagare."
            />
          </S.BodyInner>
        </S.Body>

        <S.Body value="4">
          <AccordionHeader title="Avstämning mot förgodkända UE" />

          <S.BodyInner>
            <Textarea
              label="Resultat"
              name="preApprovedSubcontractorResult"
              placeholder="Beskriv resultatet..."
            />

            <RadioInput
              state={watch('preApprovedSubcontractorActionsNeeded')}
              control={control}
              question="Åtgärder behövs"
              options={[
                { value: 'no', label: 'Nej' },
                { value: 'yes', label: 'Ja' },
              ]}
              name="preApprovedSubcontractorActionsNeeded"
            />

            <Textarea
              label="Åtgärder"
              name="preApprovedSubcontractorActions"
              placeholder="Beskriv åtgärder som gjorts..."
            />

            <Rules rule="I enlighet med avtalsvillkor för underentreprenörer och bemanningsföretag vid anlitade på Byggavtalets tillämpningsområde finns det särskilda entreprenadsrättsliga villkor. Ett av kraven är att underentreprenören alt. bemanningsföretaget skriftligen rapporterar vilka underentreprenörer och/eller bemanningsföretag denne anlitar i alla led. " />
          </S.BodyInner>
        </S.Body>

        <S.Body value="2">
          <AccordionHeader title="Arbetsmiljö" />

          <S.BodyInner>
            <Textarea
              label="Resultat"
              name="workEnvironmentResult"
              placeholder="Beskriv resultatet..."
            />

            <RadioInput
              state={watch('workEnvironmentActionsNeeded')}
              control={control}
              question="Åtgärder behövs"
              options={[
                { value: 'no', label: 'Nej' },
                { value: 'yes', label: 'Ja' },
              ]}
              name="workEnvironmentActionsNeeded"
            />

            <Textarea
              label="Åtgärder"
              name="workEnvironmentActions"
              placeholder="Beskriv åtgärder som gjorts..."
            />

            {workEnvironmentFile && workEnvironmentFile.length > 0 && (
              <Photos data={workEnvironmentFile} />
            )}

            <Rules rule="För alla arbetsgivare gäller föreskrifterna om systematiskt arbetsmiljöarbete (AFS 2011:1). Krav på systematiskt arbetsmiljöarbete innebär att undersöka, genomföra och följa upp verksamhet för att förebygga olyckor och ohälsa. Det skall finnas arbetsmiljöpolicy och rutiner som dokumenteras skriftligt om det finns minst 10 arbetstagare i verksamheten (5 §). Syftet med kraven i arbetsmiljölagen, arbetsmiljöförordningen och Arbetarskyddsstyrelsens och Arbetsmiljöverkets regler är att förebygga ohälsa och olycksfall i arbetet och att uppnå en tillfredsställande arbetsmiljö." />
          </S.BodyInner>
        </S.Body>

        <S.Body value="10">
          <AccordionHeader title="Personlig säkerhetsutrustning" />

          <S.BodyInner>
            <Textarea
              label="Resultat"
              name="safetyEquipmentResult"
              placeholder="Beskriv resultatet..."
            />

            <RadioInput
              state={watch('safetyEquipmentActionsNeeded')}
              control={control}
              question="Åtgärder behövs"
              options={[
                { value: 'no', label: 'Nej' },
                { value: 'yes', label: 'Ja' },
              ]}
              name="safetyEquipmentActionsNeeded"
            />

            <Textarea
              label="Åtgärder"
              name="safetyEquipmentActions"
              placeholder="Beskriv åtgärder som gjorts..."
            />

            <Rules rule="Personlig skyddsutrustning ska användas i enlighet med Arbetsmiljöverkets föreskrifter om användning av personlig skyddsutrustning (AFS 2001:3) och den ska användas om den är ändamålsenlig i förhållande till risker, anpassad till förhållandena på arbetsplatsen och aktuella arbetsställningar samt arbetarens hälsotillstånd." />
          </S.BodyInner>
        </S.Body>

        <S.Body value="1">
          <AccordionHeader title="Skalskydd/inhängnad" />

          <S.BodyInner>
            <Textarea
              label="Resultat"
              name="shellProtectionResult"
              placeholder="Beskriv resultatet..."
            />

            <RadioInput
              state={watch('shellProtectionActionsNeeded')}
              control={control}
              question="Åtgärder behövs"
              options={[
                { value: 'no', label: 'Nej' },
                { value: 'yes', label: 'Ja' },
              ]}
              name="shellProtectionActionsNeeded"
            />

            <Textarea
              label="Åtgärder"
              name="shellProtectionActions"
              placeholder="Beskriv åtgärder som gjorts..."
            />

            {shellProtectionFile && shellProtectionFile.length > 0 && (
              <Photos data={shellProtectionFile} />
            )}

            <Rules
              rule="I enlighet med Arbetsmiljöverkets föreskrifter, AFS 1999:3, ska
                en plats eller ett område där byggnads- eller anläggningsarbete
                utförs avgränsas på väl synligt och lätt identifierbart sätt (23
                §). Anledning till kravet på avgränsning är att obehöriga inte
                skall komma in på plats eller område där byggnads- eller
                anläggningsarbete utförs och där de kan utgöra en risk för
                olyckor eller att stjäla."
            />
          </S.BodyInner>
        </S.Body>

        <S.Body value="9">
          <AccordionHeader title="Tecken på onykterhet och drogpåverkan" />

          <S.BodyInner>
            <Textarea
              label="Resultat"
              name="intoxicationResult"
              placeholder="Beskriv resultatet..."
            />

            <RadioInput
              state={watch('intoxicationActionsNeeded')}
              control={control}
              question="Åtgärder behövs"
              options={[
                { value: 'no', label: 'Nej' },
                { value: 'yes', label: 'Ja' },
              ]}
              name="intoxicationActionsNeeded"
            />

            <Textarea
              label="Åtgärder"
              name="intoxicationActions"
              placeholder="Beskriv åtgärder som gjorts..."
            />

            <Rules
              rule="Under kontrollen fanns ingen antydan på att någon på arbetsplatsen skulle varit påverkad av alkohol eller droger. 
              Regelverk: I Arbetsmiljölagen uppges att arbetsgivaren ska klargöra vilka interna regler och rutiner som gäller om arbetstagare uppträder påverkad av alkohol eller andra berusningsmedel i arbetet. Den anställde har rätt att veta vad som gäller på jobbet när det gäller alkohol och droger.
              För att klargöra vad som gäller bör arbetsgivaren upprätta en alkohol- och drogpolicy och en handlingsplan. Policyn ska innehålla tydliga ställningstaganden för hur arbetsplatsen ser på alkohol och droger, till exempel om alkohol får serveras på personalfester och vad som händer om någon kommer påverkad till jobbet. Handlingsplanen ska fungera som en vägledning och tala om hur man ska uppnå det som står i policyn.
              Kontroller ska alltid ske i avskildhet, så att inte någon kränks (11 § Ordningsregler. Punkt 1.6 i Byggavtalet). Provtagningen ska också utföras av personal som är kunnig och vet vad som är rätt när det gäller provtagning och utrustning.
              För att utföra ett drogtest måste arbetsgivaren ha ett skäl. Det vanligaste är säkerheten på arbetsplatsen.
              Det finns inte någon lag som reglerar arbetsgivares möjligheter att testa sin anställda, i stället används kollektiv- eller anställningsavtal. Enligt 11 § MBL ska arbetsgivare förhandla om alkoholkontroller med facket."
            />
          </S.BodyInner>
        </S.Body>

        <S.Body value="11">
          <AccordionHeader title="Övrig kontrollverksamhet" />

          <S.BodyInner>
            <Textarea
              label="Resultat"
              name="otherResult"
              placeholder="Beskriv resultatet..."
            />

            <RadioInput
              state={watch('otherActionsNeeded')}
              control={control}
              question="Åtgärder behövs"
              options={[
                { value: 'no', label: 'Nej' },
                { value: 'yes', label: 'Ja' },
              ]}
              name="otherActionsNeeded"
            />

            <Textarea
              label="Åtgärder"
              name="otherActions"
              placeholder="Beskriv åtgärder som gjorts..."
            />

            <Rules rule="I Diskrimineringslagen framgår att Diskriminering är förbjudet inom arbetslivet. Därför har arbetsgivare ett ansvar för att arbetstagare inte blir utsatta för diskriminering. Arbetsmiljöverkets föreskrifter AFS 2020:1 uppger att arbetsplatser ska ha separata omklädningsrum för män och kvinnor, om de ska användas samtidigt enligt föreskrifter för arbetsplatsens utformning. Likaså ska det finnas skilda duschar för män och kvinnor, i de fall duscharna behöver användas samtidigt." />
          </S.BodyInner>
        </S.Body>

        <S.Footer>
          <S.Button $outlined onClick={navigateToSummary}>
            Föregående
          </S.Button>

          <S.Button onClick={navigateToDeviation}>Nästa</S.Button>
        </S.Footer>
      </S.ResultInner>
    </S.ResultContainer>
  );
};
