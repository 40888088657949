import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useApi } from '../useApi';
import { workRegisterKeys } from './workRegisters.key';

export type SortPersonnel =
  | 'firstName_ASC'
  | 'firstName_DESC'
  | 'lastName_ASC'
  | 'lastName_DESC'
  | 'companyName_ASC'
  | 'companyName_DESC'
  | 'special'
  | 'no_safe';

export function useGetAllPersonnel(
  controlId: string,
  workRegisterId: string | undefined,
  page: number,
  sort: SortPersonnel = 'firstName_ASC'
) {
  const getApi = useApi();
  const [count, setCount] = useState(0);

  const getAllPersonnel = async () => {
    const api = await getApi();
    const take = 20;
    const skip = page * take;

    if (!workRegisterId) return Promise.resolve([]);

    const res = await api.personnelFindAll(workRegisterId, take, skip, sort);

    setCount(Number(res.headers['x-total-count']));
    return res.data;
  };

  const query = useQuery({
    queryKey: workRegisterKeys.personnelFindMany(controlId, { page, sort }),
    queryFn: getAllPersonnel,
    keepPreviousData: true,
  });

  return {
    ...query,
    count,
  };
}
