import React from 'react';
import { S } from './WorkRegisterTable.styles';
import { FindAllPersonnelResponse } from '@ansvar-sakerhet/api-client';
import { Row, UseExpandedRowProps, useExpanded, useTable } from 'react-table';
import { useWorkRegisterTableData } from './hooks/useWorkRegisterTableData';
import { WorkRegisterTableSubRow } from '../WorkRegisterTableSubRow';
import { Pagination } from '../../../../../../components/Pagination';
import { useControl } from '../../../../components/ControlLayout';
import { useGetPersonnelFiles } from '../../../../../../traits/api/workRegisters/useGetPersonnelFiles';
import { useGetPersonnel } from '../../../../../../traits/api/workRegisters/useGetPersonnel';
import { useWorkRegisterUpload } from '../../../ContactInfo/hooks/useWorkRegisterUpload';
import { useDownloadCSV } from '../../../ContactInfo/hooks/useDownloadCSV';

type Props = {
  data: FindAllPersonnelResponse[];
  page: number;
  setPage: (page: number) => void;
  numberOfPages: number;
  loading: boolean;
  showExtra: boolean;
};

type ExpandedRow = UseExpandedRowProps<
  Row<ReturnType<typeof useWorkRegisterTableData>['data']>
>;

export const WorkRegisterTable: React.FC<Props> = (props) => {
  const data = useWorkRegisterTableData(props.data);
  const table = useTable(data, useExpanded);
  const { data: control } = useControl();
  const workRegisterUpload = useWorkRegisterUpload();
  const csv = useDownloadCSV();

  const [hoverEPL, setHoverEPL] = React.useState(false);
  const [hoverBolt, setHoverBolt] = React.useState(false);
  return (
    <S.TableContainer>
      <S.Table {...table.getTableProps()}>
        <S.THead>
          {table.headerGroups.map((headerGroup) => {
            return (
              <S.THeadRow
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.getHeaderGroupProps().key}
              >
                {headerGroup.headers.map((column) => {
                  if (
                    !props.showExtra &&
                    (column.id === 'id06' || column.id === 'workRegister')
                  ) {
                    return null;
                  }
                  return (
                    <S.THeadCell
                      {...column.getHeaderProps()}
                      key={column.getHeaderProps().key}
                    >
                      {column.render('Header')}
                    </S.THeadCell>
                  );
                })}
              </S.THeadRow>
            );
          })}
        </S.THead>

        <S.TBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);
            const expanded = row as unknown as ExpandedRow;
            const files = useGetPersonnelFiles(
              row.original.personnelId,
              control.controlId
            );

            const personnel = useGetPersonnel(
              row.original.personnelId,
              control.controlId
            );

            if (files.isLoading || files.isError) {
              return <S.H4 key={row.getRowProps().key}>Loading...</S.H4>;
            }

            return (
              <React.Fragment key={row.getRowProps().key}>
                <S.TBodyRow
                  {...row.getRowProps()}
                  onClick={() => expanded.toggleRowExpanded()}
                >
                  {row.cells.map((cell) => {
                    if (cell.column.id === 'id06') {
                      if (!props.showExtra) return null;
                      return (
                        <S.TBodyCell
                          {...cell.getCellProps()}
                          key={cell.getCellProps().key}
                        >
                          <S.ID06Tag
                            $type={cell.row.original.id06}
                            $hasComment={
                              cell.row.original.person.hasID06CardComment
                            }
                          >
                            {cell.render('Cell')}
                          </S.ID06Tag>
                        </S.TBodyCell>
                      );
                    }

                    if (cell.column.id === 'workRegister') {
                      if (!props.showExtra) return null;
                      return (
                        <S.TBodyCell
                          {...cell.getCellProps()}
                          key={cell.getCellProps().key}
                        >
                          <S.WorkRegisterTag
                            $type={cell.row.original.workRegister}
                            $hasComment={
                              cell.row.original.person
                                .hasPersonnelQuestionnaireComment
                            }
                          >
                            {cell.render('Cell')}
                          </S.WorkRegisterTag>
                        </S.TBodyCell>
                      );
                    }

                    if (cell.column.id === 'name') {
                      return (
                        <S.TBodyCell
                          {...cell.getCellProps()}
                          key={cell.getCellProps().key}
                        >
                          {!personnel.data?.arrivedAt && (
                            <div
                              onMouseEnter={() => setHoverEPL(true)}
                              onMouseLeave={() => setHoverEPL(false)}
                              role="button"
                            >
                              {hoverEPL ? (
                                <S.HoverText>Ej incheckad i EPL</S.HoverText>
                              ) : null}
                              <S.Icon src="Alert" />
                            </div>
                          )}
                          {personnel.data?.arrivedAt &&
                            !personnel.data.questionnaire?.hasIdCard && (
                              <div
                                onMouseEnter={() => setHoverBolt(true)}
                                onMouseLeave={() => setHoverBolt(false)}
                                role="button"
                              >
                                {hoverBolt ? (
                                  <S.HoverText>Hittades ej</S.HoverText>
                                ) : null}
                                <S.Icon src="Bolt" />
                              </div>
                            )}
                          {cell.render('Cell')}
                          {files.data?.length ? <S.Icon src="File" /> : null}
                        </S.TBodyCell>
                      );
                    }
                    return (
                      <S.TBodyCell
                        {...cell.getCellProps()}
                        key={cell.getCellProps().key}
                      >
                        {cell.render('Cell')}
                      </S.TBodyCell>
                    );
                  })}

                  <S.TriggerWrapper>
                    <S.Trigger $isExpanded={expanded.isExpanded}>
                      <S.AnimatedIcon src="ChevronDown" />
                    </S.Trigger>
                  </S.TriggerWrapper>
                </S.TBodyRow>

                {expanded.isExpanded && (
                  <tr>
                    <td colSpan={table.columns.length + 1}>
                      <WorkRegisterTableSubRow
                        personnelId={row.original.personnelId}
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </S.TBody>
      </S.Table>
      <S.Button
        $outlined
        onClick={() => workRegisterUpload.handleUploadClick()}
      >
        Ladda upp personalliggare ({control.workRegister.numUploads})
      </S.Button>
      <S.Button
        onClick={() => {
          csv.download().then((file) => {
            const blob = new Blob([file], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = 'personnel.csv';
            link.href = url;
            link.click();
          });
        }}
      >
        Ladda ner csv för id06
      </S.Button>
      <S.Input
        type="file"
        ref={workRegisterUpload.inputRef}
        onChange={workRegisterUpload.handleFileChange}
      />
      <S.PaginationContainer>
        <Pagination
          page={props.page}
          setPage={props.setPage}
          numberOfPages={props.numberOfPages}
        />
      </S.PaginationContainer>
    </S.TableContainer>
  );
};
