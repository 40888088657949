/* tslint:disable */
/* eslint-disable */
/**
 * Ansvar Säkerhet Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Amount
 */
export interface Amount {
    /**
     * 
     * @type {number}
     * @memberof Amount
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface BackgroundControlFileWithFile
 */
export interface BackgroundControlFileWithFile {
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlFileWithFile
     */
    'backgroundControlFileId': string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlFileWithFile
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlFileWithFile
     */
    'backgroundControlId': string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlFileWithFile
     */
    'comment': string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlFileWithFile
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlFileWithFile
     */
    'updatedAt': string;
    /**
     * 
     * @type {any}
     * @memberof BackgroundControlFileWithFile
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface BackgroundControlOrder
 */
export interface BackgroundControlOrder {
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlOrder
     */
    'backgroundControlOrderId': string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlOrder
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlOrder
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlOrder
     */
    'customerUserId': string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlOrder
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlOrder
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface BackgroundControlWithFiles
 */
export interface BackgroundControlWithFiles {
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlWithFiles
     */
    'backgroundControlId': string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlWithFiles
     */
    'risk': BackgroundControlWithFilesRiskEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BackgroundControlWithFiles
     */
    'optedOut': boolean;
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlWithFiles
     */
    'frameCompanyId': string | null;
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlWithFiles
     */
    'projectCompanyRegistrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlWithFiles
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlWithFiles
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<BackgroundControlFileWithFile>}
     * @memberof BackgroundControlWithFiles
     */
    'reportFiles': Array<BackgroundControlFileWithFile>;
    /**
     * 
     * @type {string}
     * @memberof BackgroundControlWithFiles
     */
    'projectId': string;
}

export const BackgroundControlWithFilesRiskEnum = {
    NoRisk: 'NoRisk',
    LesserRisk: 'LesserRisk',
    MediumHighRisk: 'MediumHighRisk',
    HighRisk: 'HighRisk'
} as const;

export type BackgroundControlWithFilesRiskEnum = typeof BackgroundControlWithFilesRiskEnum[keyof typeof BackgroundControlWithFilesRiskEnum];

/**
 * 
 * @export
 * @interface BackgroundResults
 */
export interface BackgroundResults {
    /**
     * 
     * @type {number}
     * @memberof BackgroundResults
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof BackgroundResults
     */
    'noRisk': number;
    /**
     * 
     * @type {number}
     * @memberof BackgroundResults
     */
    'lesserRisk': number;
    /**
     * 
     * @type {number}
     * @memberof BackgroundResults
     */
    'mediumHighRisk': number;
    /**
     * 
     * @type {number}
     * @memberof BackgroundResults
     */
    'highRisk': number;
}
/**
 * 
 * @export
 * @interface City
 */
export interface City {
    /**
     * 
     * @type {string}
     * @memberof City
     */
    'cityId': string;
    /**
     * 
     * @type {string}
     * @memberof City
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof City
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof City
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof City
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface Client
 */
export interface Client {
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'registrationNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'controlId': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'nationality': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    'isPreQualified': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    'hasReportingObligation': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    'isReportingObligationFulfilled': boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    'isPaused': boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface CompanyWithBackgroundControl
 */
export interface CompanyWithBackgroundControl {
    /**
     * 
     * @type {string}
     * @memberof CompanyWithBackgroundControl
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithBackgroundControl
     */
    'controlId': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithBackgroundControl
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithBackgroundControl
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithBackgroundControl
     */
    'nationality': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithBackgroundControl
     */
    'isPreQualified': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithBackgroundControl
     */
    'hasReportingObligation': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithBackgroundControl
     */
    'isReportingObligationFulfilled': boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithBackgroundControl
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithBackgroundControl
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithBackgroundControl
     */
    'isPaused': boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithBackgroundControl
     */
    'comment': string;
    /**
     * 
     * @type {Array<BackgroundControlWithFiles>}
     * @memberof CompanyWithBackgroundControl
     */
    'backgroundControls': Array<BackgroundControlWithFiles>;
    /**
     * 
     * @type {Array<BackgroundControlOrder>}
     * @memberof CompanyWithBackgroundControl
     */
    'BackgroundControlOrder': Array<BackgroundControlOrder>;
}
/**
 * 
 * @export
 * @interface CompanyWithPersonnel
 */
export interface CompanyWithPersonnel {
    /**
     * 
     * @type {string}
     * @memberof CompanyWithPersonnel
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithPersonnel
     */
    'controlId': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithPersonnel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithPersonnel
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithPersonnel
     */
    'nationality': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithPersonnel
     */
    'isPreQualified': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithPersonnel
     */
    'hasReportingObligation': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithPersonnel
     */
    'isReportingObligationFulfilled': boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithPersonnel
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithPersonnel
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithPersonnel
     */
    'isPaused': boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithPersonnel
     */
    'comment': string;
    /**
     * 
     * @type {Array<Personnel>}
     * @memberof CompanyWithPersonnel
     */
    'personnel': Array<Personnel>;
}
/**
 * 
 * @export
 * @interface Control
 */
export interface Control {
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'inspectionCompany': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'inspectionResponsible': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'phoneNumber': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'inspectors': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'controlId': string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'creatorId': string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'cityId': string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'inspectionId': string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'assignmentDescription': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'summaryAndRecommendations': string | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'shellProtectionProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'shellProtectionConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'shellProtectionResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'shellProtectionActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'workEnvironmentProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'workEnvironmentConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'workEnvironmentResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'workEnvironmentActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'workRegisterProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'workRegisterConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'workRegisterResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'workRegisterActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'preApprovedSubcontractorProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'preApprovedSubcontractorConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'preApprovedSubcontractorResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'preApprovedSubcontractorActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'workPermitProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'workPermitConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'workPermitResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'workPermitActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'id06DeviationsProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'id06DeviationsConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'id06DeviationsResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'id06DeviationsActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'identificationProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'identificationConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'identificationResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'identificationActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'secondmentRegulationsProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'secondmentRegulationsConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'secondmentRegulationsResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'secondmentRegulationsActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'intoxicationProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'intoxicationConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'intoxicationResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'intoxicationActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'safetyEquipmentProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'safetyEquipmentConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'safetyEquipmentResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'safetyEquipmentActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'otherProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'otherConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'otherResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'otherActions': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'creatorName': string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'mainContractor': string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'siteManager': string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'gdprDataDeletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'reportStatedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'reportCompletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'completedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof Control
     */
    'isCompleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'mainContractorCompanyId': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'siteManagerPersonnelId': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Control
     */
    'shellProtectionActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Control
     */
    'workEnvironmentActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Control
     */
    'workRegisterActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Control
     */
    'preApprovedSubcontractorActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Control
     */
    'workPermitActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Control
     */
    'id06DeviationsActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Control
     */
    'identificationActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Control
     */
    'secondmentRegulationsActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Control
     */
    'intoxicationActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Control
     */
    'safetyEquipmentActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Control
     */
    'otherActionsNeeded': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'controlFile': string | null;
}
/**
 * 
 * @export
 * @interface ControlFileWithFile
 */
export interface ControlFileWithFile {
    /**
     * 
     * @type {string}
     * @memberof ControlFileWithFile
     */
    'controlFileId': string;
    /**
     * 
     * @type {string}
     * @memberof ControlFileWithFile
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof ControlFileWithFile
     */
    'controlId': string;
    /**
     * 
     * @type {string}
     * @memberof ControlFileWithFile
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlFileWithFile
     */
    'type': ControlFileWithFileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ControlFileWithFile
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ControlFileWithFile
     */
    'updatedAt': string;
    /**
     * 
     * @type {any}
     * @memberof ControlFileWithFile
     */
    'file': any;
}

export const ControlFileWithFileTypeEnum = {
    WorkEnvironment: 'WorkEnvironment',
    ShellProtection: 'ShellProtection'
} as const;

export type ControlFileWithFileTypeEnum = typeof ControlFileWithFileTypeEnum[keyof typeof ControlFileWithFileTypeEnum];

/**
 * 
 * @export
 * @interface ControlStatistics
 */
export interface ControlStatistics {
    /**
     * 
     * @type {string}
     * @memberof ControlStatistics
     */
    'controlStatisticsId': string;
    /**
     * 
     * @type {string}
     * @memberof ControlStatistics
     */
    'controlId': string;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'numberOfPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'numberOfCheckedInPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'percentageOfCheckedInPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'numberOfCheckedInPersonnelEnd': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'percentageOfCheckedInPersonnelEnd': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'numberOfControlledPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'percentageOfControlledPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'numberOfCheckedInAndControlledPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'percentageOfCheckedInAndControlledPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'numberOfAdditionalPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'percentageOfAdditionalPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'numberOfNotControlledPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'percentageOfNotControlledPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'numberOfPersonnelWithIdCardDeviations': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'percentageOfPersonnelWithIdCardDeviations': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'numberOfPersonnelWithInvalidIdentification': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'percentageOfPersonnelWithInvalidIdentification': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'numberOfPersonnelWithoutSafeConstructionTraining': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'percentageOfPersonnelWithoutSafeConstructionTraining': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'numberOfPersonnelWithoutSafetyEquipment': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'percentageOfPersonnelWithoutSafetyEquipment': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'numberOfPersonnelWithoutWorkPermit': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'percentageOfPersonnelWithoutWorkPermit': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'numberOfPersonnelWithoutCertificate': number;
    /**
     * 
     * @type {number}
     * @memberof ControlStatistics
     */
    'percentageOfPersonnelWithoutCertificate': number;
    /**
     * 
     * @type {string}
     * @memberof ControlStatistics
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ControlStatistics
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ControlWithCounterMeasures
 */
export interface ControlWithCounterMeasures {
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'inspectionCompany': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'inspectionResponsible': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'phoneNumber': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'inspectors': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'controlId': string;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'creatorId': string;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'cityId': string;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'inspectionId': string;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'assignmentDescription': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'summaryAndRecommendations': string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'shellProtectionProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'shellProtectionConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'shellProtectionResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'shellProtectionActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'workEnvironmentProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'workEnvironmentConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'workEnvironmentResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'workEnvironmentActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'workRegisterProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'workRegisterConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'workRegisterResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'workRegisterActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'preApprovedSubcontractorProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'preApprovedSubcontractorConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'preApprovedSubcontractorResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'preApprovedSubcontractorActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'workPermitProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'workPermitConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'workPermitResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'workPermitActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'id06DeviationsProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'id06DeviationsConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'id06DeviationsResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'id06DeviationsActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'identificationProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'identificationConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'identificationResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'identificationActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'secondmentRegulationsProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'secondmentRegulationsConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'secondmentRegulationsResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'secondmentRegulationsActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'intoxicationProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'intoxicationConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'intoxicationResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'intoxicationActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'safetyEquipmentProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'safetyEquipmentConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'safetyEquipmentResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'safetyEquipmentActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'otherProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithCounterMeasures
     */
    'otherConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'otherResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'otherActions': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'creatorName': string;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'mainContractor': string;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'siteManager': string;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'gdprDataDeletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'reportStatedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'reportCompletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'completedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlWithCounterMeasures
     */
    'isCompleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'mainContractorCompanyId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'siteManagerPersonnelId': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlWithCounterMeasures
     */
    'shellProtectionActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlWithCounterMeasures
     */
    'workEnvironmentActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlWithCounterMeasures
     */
    'workRegisterActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlWithCounterMeasures
     */
    'preApprovedSubcontractorActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlWithCounterMeasures
     */
    'workPermitActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlWithCounterMeasures
     */
    'id06DeviationsActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlWithCounterMeasures
     */
    'identificationActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlWithCounterMeasures
     */
    'secondmentRegulationsActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlWithCounterMeasures
     */
    'intoxicationActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlWithCounterMeasures
     */
    'safetyEquipmentActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlWithCounterMeasures
     */
    'otherActionsNeeded': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithCounterMeasures
     */
    'controlFile': string | null;
    /**
     * 
     * @type {Array<DeviationCounterMeasure>}
     * @memberof ControlWithCounterMeasures
     */
    'deviationCounterMeasures': Array<DeviationCounterMeasure>;
    /**
     * 
     * @type {Array<CompanyWithBackgroundControl>}
     * @memberof ControlWithCounterMeasures
     */
    'companies': Array<CompanyWithBackgroundControl>;
    /**
     * 
     * @type {Array<ControlFileWithFile>}
     * @memberof ControlWithCounterMeasures
     */
    'files': Array<ControlFileWithFile>;
}
/**
 * 
 * @export
 * @interface CreateBackgroundControlDto
 */
export interface CreateBackgroundControlDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBackgroundControlDto
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBackgroundControlDto
     */
    'risk': CreateBackgroundControlDtoRiskEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBackgroundControlDto
     */
    'optedOut': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateBackgroundControlDto
     */
    'companyRegistrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBackgroundControlDto
     */
    'projectId': string;
}

export const CreateBackgroundControlDtoRiskEnum = {
    NoRisk: 'NoRisk',
    LesserRisk: 'LesserRisk',
    MediumHighRisk: 'MediumHighRisk',
    HighRisk: 'HighRisk'
} as const;

export type CreateBackgroundControlDtoRiskEnum = typeof CreateBackgroundControlDtoRiskEnum[keyof typeof CreateBackgroundControlDtoRiskEnum];

/**
 * 
 * @export
 * @interface CreateBackgroundControlFrameDto
 */
export interface CreateBackgroundControlFrameDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBackgroundControlFrameDto
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBackgroundControlFrameDto
     */
    'risk': CreateBackgroundControlFrameDtoRiskEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBackgroundControlFrameDto
     */
    'optedOut': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateBackgroundControlFrameDto
     */
    'frameCompanyId': string;
}

export const CreateBackgroundControlFrameDtoRiskEnum = {
    NoRisk: 'NoRisk',
    LesserRisk: 'LesserRisk',
    MediumHighRisk: 'MediumHighRisk',
    HighRisk: 'HighRisk'
} as const;

export type CreateBackgroundControlFrameDtoRiskEnum = typeof CreateBackgroundControlFrameDtoRiskEnum[keyof typeof CreateBackgroundControlFrameDtoRiskEnum];

/**
 * 
 * @export
 * @interface CreateBackgroundControlOrderDto
 */
export interface CreateBackgroundControlOrderDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBackgroundControlOrderDto
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBackgroundControlOrderDto
     */
    'projectId': string;
}
/**
 * 
 * @export
 * @interface CreateBackgroundControlOrderFrameDto
 */
export interface CreateBackgroundControlOrderFrameDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBackgroundControlOrderFrameDto
     */
    'frameCompanyId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBackgroundControlOrderFrameDto
     */
    'clientId': string;
}
/**
 * 
 * @export
 * @interface CreateControlDto
 */
export interface CreateControlDto {
    /**
     * 
     * @type {string}
     * @memberof CreateControlDto
     */
    'project': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlDto
     */
    'client': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlDto
     */
    'mainContractor': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlDto
     */
    'siteManager': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlDto
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface CreateControlFileDto
 */
export interface CreateControlFileDto {
    /**
     * 
     * @type {string}
     * @memberof CreateControlFileDto
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlFileDto
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlFileDto
     */
    'type': CreateControlFileDtoTypeEnum;
}

export const CreateControlFileDtoTypeEnum = {
    WorkEnvironment: 'WorkEnvironment',
    ShellProtection: 'ShellProtection'
} as const;

export type CreateControlFileDtoTypeEnum = typeof CreateControlFileDtoTypeEnum[keyof typeof CreateControlFileDtoTypeEnum];

/**
 * 
 * @export
 * @interface CreateControlFileResponse
 */
export interface CreateControlFileResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateControlFileResponse
     */
    'controlFileId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlFileResponse
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlFileResponse
     */
    'controlId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlFileResponse
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlFileResponse
     */
    'type': CreateControlFileResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateControlFileResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlFileResponse
     */
    'updatedAt': string;
}

export const CreateControlFileResponseTypeEnum = {
    WorkEnvironment: 'WorkEnvironment',
    ShellProtection: 'ShellProtection'
} as const;

export type CreateControlFileResponseTypeEnum = typeof CreateControlFileResponseTypeEnum[keyof typeof CreateControlFileResponseTypeEnum];

/**
 * 
 * @export
 * @interface CreateControlResponse
 */
export interface CreateControlResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'inspectionCompany': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'inspectionResponsible': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'phoneNumber': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'inspectors': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'controlId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'creatorId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'cityId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'inspectionId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'assignmentDescription': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'summaryAndRecommendations': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'shellProtectionProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'shellProtectionConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'shellProtectionResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'shellProtectionActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'workEnvironmentProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'workEnvironmentConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'workEnvironmentResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'workEnvironmentActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'workRegisterProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'workRegisterConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'workRegisterResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'workRegisterActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'preApprovedSubcontractorProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'preApprovedSubcontractorConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'preApprovedSubcontractorResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'preApprovedSubcontractorActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'workPermitProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'workPermitConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'workPermitResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'workPermitActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'id06DeviationsProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'id06DeviationsConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'id06DeviationsResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'id06DeviationsActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'identificationProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'identificationConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'identificationResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'identificationActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'secondmentRegulationsProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'secondmentRegulationsConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'secondmentRegulationsResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'secondmentRegulationsActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'intoxicationProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'intoxicationConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'intoxicationResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'intoxicationActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'safetyEquipmentProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'safetyEquipmentConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'safetyEquipmentResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'safetyEquipmentActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'otherProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateControlResponse
     */
    'otherConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'otherResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'otherActions': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'creatorName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'mainContractor': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'siteManager': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'gdprDataDeletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'reportStatedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'reportCompletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'completedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateControlResponse
     */
    'isCompleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'mainContractorCompanyId': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'siteManagerPersonnelId': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateControlResponse
     */
    'shellProtectionActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateControlResponse
     */
    'workEnvironmentActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateControlResponse
     */
    'workRegisterActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateControlResponse
     */
    'preApprovedSubcontractorActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateControlResponse
     */
    'workPermitActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateControlResponse
     */
    'id06DeviationsActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateControlResponse
     */
    'identificationActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateControlResponse
     */
    'secondmentRegulationsActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateControlResponse
     */
    'intoxicationActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateControlResponse
     */
    'safetyEquipmentActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateControlResponse
     */
    'otherActionsNeeded': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateControlResponse
     */
    'controlFile': string | null;
}
/**
 * 
 * @export
 * @interface CreateCustomerUserDto
 */
export interface CreateCustomerUserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerUserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerUserDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerUserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerUserDto
     */
    'lastName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCustomerUserDto
     */
    'clientIds': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateCustomerUserResponse
 */
export interface CreateCustomerUserResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerUserResponse
     */
    'customerUserId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerUserResponse
     */
    'externalId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerUserResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerUserResponse
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface CreateDeviationCounterMeasureDto
 */
export interface CreateDeviationCounterMeasureDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDeviationCounterMeasureDto
     */
    'fixedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeviationCounterMeasureDto
     */
    'fixDescription': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeviationCounterMeasureDto
     */
    'fixedBy': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeviationCounterMeasureDto
     */
    'type': CreateDeviationCounterMeasureDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDeviationCounterMeasureDto
     */
    'controlId': string;
}

export const CreateDeviationCounterMeasureDtoTypeEnum = {
    ShellProtection: 'SHELL_PROTECTION',
    WorkEnvironment: 'WORK_ENVIRONMENT',
    WorkRegister: 'WORK_REGISTER',
    PreApprovedSubcontractor: 'PRE_APPROVED_SUBCONTRACTOR',
    Id06Deviations: 'ID_06_DEVIATIONS',
    Identification: 'IDENTIFICATION',
    WorkPermit: 'WORK_PERMIT',
    Secondment: 'SECONDMENT',
    Intoxication: 'INTOXICATION',
    SafetyEquipment: 'SAFETY_EQUIPMENT',
    Other: 'OTHER'
} as const;

export type CreateDeviationCounterMeasureDtoTypeEnum = typeof CreateDeviationCounterMeasureDtoTypeEnum[keyof typeof CreateDeviationCounterMeasureDtoTypeEnum];

/**
 * 
 * @export
 * @interface CreateFaqDto
 */
export interface CreateFaqDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFaqDto
     */
    'faqCategory'?: CreateFaqDtoFaqCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqDto
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqDto
     */
    'answer': string;
}

export const CreateFaqDtoFaqCategoryEnum = {
    CustomerPortal: 'CustomerPortal',
    App: 'App'
} as const;

export type CreateFaqDtoFaqCategoryEnum = typeof CreateFaqDtoFaqCategoryEnum[keyof typeof CreateFaqDtoFaqCategoryEnum];

/**
 * 
 * @export
 * @interface CreateFaqFileDto
 */
export interface CreateFaqFileDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFaqFileDto
     */
    'fileId': string;
}
/**
 * 
 * @export
 * @interface CreateFaqFileResponse
 */
export interface CreateFaqFileResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateFaqFileResponse
     */
    'faqFileId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqFileResponse
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqFileResponse
     */
    'faqId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqFileResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqFileResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface CreateFaqResponse
 */
export interface CreateFaqResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateFaqResponse
     */
    'faqCategory': CreateFaqResponseFaqCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqResponse
     */
    'faqId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqResponse
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqResponse
     */
    'answer': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqResponse
     */
    'updatedAt': string;
}

export const CreateFaqResponseFaqCategoryEnum = {
    CustomerPortal: 'CustomerPortal',
    App: 'App'
} as const;

export type CreateFaqResponseFaqCategoryEnum = typeof CreateFaqResponseFaqCategoryEnum[keyof typeof CreateFaqResponseFaqCategoryEnum];

/**
 * 
 * @export
 * @interface CreateFrameCompanyDto
 */
export interface CreateFrameCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFrameCompanyDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFrameCompanyDto
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFrameCompanyDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface CreateNewsDto
 */
export interface CreateNewsDto {
    /**
     * 
     * @type {string}
     * @memberof CreateNewsDto
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewsDto
     */
    'header': string;
}
/**
 * 
 * @export
 * @interface CreateNewsResponse
 */
export interface CreateNewsResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateNewsResponse
     */
    'newsId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewsResponse
     */
    'header': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewsResponse
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewsResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewsResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface CreatePersonnelFileDto
 */
export interface CreatePersonnelFileDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePersonnelFileDto
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonnelFileDto
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface CreatePersonnelFileResponse
 */
export interface CreatePersonnelFileResponse {
    /**
     * 
     * @type {string}
     * @memberof CreatePersonnelFileResponse
     */
    'personnelFileId': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonnelFileResponse
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonnelFileResponse
     */
    'personnelId': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonnelFileResponse
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonnelFileResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonnelFileResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface CreateRedListedCompany
 */
export interface CreateRedListedCompany {
    /**
     * 
     * @type {string}
     * @memberof CreateRedListedCompany
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRedListedCompany
     */
    'registrationNumber': string;
}
/**
 * 
 * @export
 * @interface CreateWorkRegisterResponse
 */
export interface CreateWorkRegisterResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkRegisterResponse
     */
    'workRegisterId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkRegisterResponse
     */
    'controlId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkRegisterResponse
     */
    'businessType': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkRegisterResponse
     */
    'createdBySoftware': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateWorkRegisterResponse
     */
    'numUploads': number;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkRegisterResponse
     */
    'softwareVersion': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkRegisterResponse
     */
    'registeredAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkRegisterResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkRegisterResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface CustomerUser
 */
export interface CustomerUser {
    /**
     * 
     * @type {string}
     * @memberof CustomerUser
     */
    'customerUserId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUser
     */
    'externalId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUser
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUser
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface DeviationCounterMeasure
 */
export interface DeviationCounterMeasure {
    /**
     * 
     * @type {string}
     * @memberof DeviationCounterMeasure
     */
    'deviationCounterMeasureId': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationCounterMeasure
     */
    'fixedAt': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationCounterMeasure
     */
    'fixDescription': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationCounterMeasure
     */
    'fixedBy': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationCounterMeasure
     */
    'type': DeviationCounterMeasureTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DeviationCounterMeasure
     */
    'controlId': string;
}

export const DeviationCounterMeasureTypeEnum = {
    ShellProtection: 'SHELL_PROTECTION',
    WorkEnvironment: 'WORK_ENVIRONMENT',
    WorkRegister: 'WORK_REGISTER',
    PreApprovedSubcontractor: 'PRE_APPROVED_SUBCONTRACTOR',
    Id06Deviations: 'ID_06_DEVIATIONS',
    Identification: 'IDENTIFICATION',
    WorkPermit: 'WORK_PERMIT',
    Secondment: 'SECONDMENT',
    Intoxication: 'INTOXICATION',
    SafetyEquipment: 'SAFETY_EQUIPMENT',
    Other: 'OTHER'
} as const;

export type DeviationCounterMeasureTypeEnum = typeof DeviationCounterMeasureTypeEnum[keyof typeof DeviationCounterMeasureTypeEnum];

/**
 * 
 * @export
 * @interface FetchCompanies
 */
export interface FetchCompanies {
    /**
     * 
     * @type {Array<string>}
     * @memberof FetchCompanies
     */
    'companies': Array<string>;
}
/**
 * 
 * @export
 * @interface FindAllCitiesResponse
 */
export interface FindAllCitiesResponse {
    /**
     * 
     * @type {string}
     * @memberof FindAllCitiesResponse
     */
    'cityId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllCitiesResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllCitiesResponse
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllCitiesResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllCitiesResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface FindAllClientsResponse
 */
export interface FindAllClientsResponse {
    /**
     * 
     * @type {string}
     * @memberof FindAllClientsResponse
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllClientsResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllClientsResponse
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllClientsResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllClientsResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllClientsResponse
     */
    'registrationNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface FindAllCompaniesResponse
 */
export interface FindAllCompaniesResponse {
    /**
     * 
     * @type {string}
     * @memberof FindAllCompaniesResponse
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllCompaniesResponse
     */
    'controlId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllCompaniesResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllCompaniesResponse
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllCompaniesResponse
     */
    'nationality': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllCompaniesResponse
     */
    'isPreQualified': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllCompaniesResponse
     */
    'hasReportingObligation': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllCompaniesResponse
     */
    'isReportingObligationFulfilled': boolean;
    /**
     * 
     * @type {string}
     * @memberof FindAllCompaniesResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllCompaniesResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllCompaniesResponse
     */
    'isPaused': boolean;
    /**
     * 
     * @type {string}
     * @memberof FindAllCompaniesResponse
     */
    'comment': string;
    /**
     * 
     * @type {Array<BackgroundControlWithFiles>}
     * @memberof FindAllCompaniesResponse
     */
    'backgroundControls': Array<BackgroundControlWithFiles>;
    /**
     * 
     * @type {Array<Control>}
     * @memberof FindAllCompaniesResponse
     */
    'mainContractorOnControl': Array<Control>;
    /**
     * 
     * @type {Array<BackgroundControlOrder>}
     * @memberof FindAllCompaniesResponse
     */
    'BackgroundControlOrder': Array<BackgroundControlOrder>;
}
/**
 * 
 * @export
 * @interface FindAllCompanyStatisticsResponse
 */
export interface FindAllCompanyStatisticsResponse {
    /**
     * 
     * @type {string}
     * @memberof FindAllCompanyStatisticsResponse
     */
    'companyId': string;
    /**
     * 
     * @type {number}
     * @memberof FindAllCompanyStatisticsResponse
     */
    'numberOfPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllCompanyStatisticsResponse
     */
    'numberOfCheckedInPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllCompanyStatisticsResponse
     */
    'numberOfControlledPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllCompanyStatisticsResponse
     */
    'numberOfControlledAndSignedInPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllCompanyStatisticsResponse
     */
    'numberOfNotFoundPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllCompanyStatisticsResponse
     */
    'numberOfDeviatingId06Cards': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllCompanyStatisticsResponse
     */
    'numberOfPersonnelWithoutSafeConstructionTraining': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllCompanyStatisticsResponse
     */
    'numberOfInactiveId06Cards': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllCompanyStatisticsResponse
     */
    'numberOfDisabledId06Cards': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllCompanyStatisticsResponse
     */
    'numberOfInvalidId06Cards': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllCompanyStatisticsResponse
     */
    'numberOfPersonnelWithoutValidA1Certificate': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllCompanyStatisticsResponse
     */
    'numberOfMissingId06Cards': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllCompanyStatisticsResponse
     */
    'percentageOfCheckedInPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllCompanyStatisticsResponse
     */
    'numberOfPersonnelWithInvalidIdentification': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllCompanyStatisticsResponse
     */
    'numberOfPersonnelWithoutSafetyEquipment': number;
    /**
     * 
     * @type {number}
     * @memberof FindAllCompanyStatisticsResponse
     */
    'numberOfPersonnelWithoutWorkPermit': number;
    /**
     * 
     * @type {Company}
     * @memberof FindAllCompanyStatisticsResponse
     */
    'company': Company;
}
/**
 * 
 * @export
 * @interface FindAllControlFilesResponse
 */
export interface FindAllControlFilesResponse {
    /**
     * 
     * @type {string}
     * @memberof FindAllControlFilesResponse
     */
    'controlFileId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlFilesResponse
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlFilesResponse
     */
    'controlId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlFilesResponse
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlFilesResponse
     */
    'type': FindAllControlFilesResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlFilesResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlFilesResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {any}
     * @memberof FindAllControlFilesResponse
     */
    'file': any;
}

export const FindAllControlFilesResponseTypeEnum = {
    WorkEnvironment: 'WorkEnvironment',
    ShellProtection: 'ShellProtection'
} as const;

export type FindAllControlFilesResponseTypeEnum = typeof FindAllControlFilesResponseTypeEnum[keyof typeof FindAllControlFilesResponseTypeEnum];

/**
 * 
 * @export
 * @interface FindAllControlStatisticsResponse
 */
export interface FindAllControlStatisticsResponse {
    /**
     * 
     * @type {FindSummaryCurrentControl}
     * @memberof FindAllControlStatisticsResponse
     */
    'control': FindSummaryCurrentControl;
    /**
     * 
     * @type {FindSummaryPercentages}
     * @memberof FindAllControlStatisticsResponse
     */
    'project': FindSummaryPercentages;
    /**
     * 
     * @type {FindSummaryPercentages}
     * @memberof FindAllControlStatisticsResponse
     */
    'client': FindSummaryPercentages;
    /**
     * 
     * @type {FindSummaryPercentages}
     * @memberof FindAllControlStatisticsResponse
     */
    'all': FindSummaryPercentages;
}
/**
 * 
 * @export
 * @interface FindAllControlsResponse
 */
export interface FindAllControlsResponse {
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'inspectionCompany': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'inspectionResponsible': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'phoneNumber': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'inspectors': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'controlId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'creatorId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'cityId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'inspectionId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'assignmentDescription': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'summaryAndRecommendations': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'shellProtectionProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'shellProtectionConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'shellProtectionResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'shellProtectionActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'workEnvironmentProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'workEnvironmentConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'workEnvironmentResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'workEnvironmentActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'workRegisterProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'workRegisterConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'workRegisterResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'workRegisterActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'preApprovedSubcontractorProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'preApprovedSubcontractorConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'preApprovedSubcontractorResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'preApprovedSubcontractorActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'workPermitProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'workPermitConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'workPermitResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'workPermitActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'id06DeviationsProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'id06DeviationsConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'id06DeviationsResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'id06DeviationsActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'identificationProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'identificationConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'identificationResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'identificationActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'secondmentRegulationsProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'secondmentRegulationsConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'secondmentRegulationsResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'secondmentRegulationsActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'intoxicationProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'intoxicationConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'intoxicationResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'intoxicationActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'safetyEquipmentProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'safetyEquipmentConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'safetyEquipmentResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'safetyEquipmentActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'otherProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllControlsResponse
     */
    'otherConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'otherResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'otherActions': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'creatorName': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'mainContractor': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'siteManager': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'gdprDataDeletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'reportStatedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'reportCompletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'completedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllControlsResponse
     */
    'isCompleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'mainContractorCompanyId': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'siteManagerPersonnelId': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllControlsResponse
     */
    'shellProtectionActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllControlsResponse
     */
    'workEnvironmentActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllControlsResponse
     */
    'workRegisterActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllControlsResponse
     */
    'preApprovedSubcontractorActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllControlsResponse
     */
    'workPermitActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllControlsResponse
     */
    'id06DeviationsActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllControlsResponse
     */
    'identificationActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllControlsResponse
     */
    'secondmentRegulationsActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllControlsResponse
     */
    'intoxicationActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllControlsResponse
     */
    'safetyEquipmentActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllControlsResponse
     */
    'otherActionsNeeded': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllControlsResponse
     */
    'controlFile': string | null;
    /**
     * 
     * @type {Client}
     * @memberof FindAllControlsResponse
     */
    'client': Client;
    /**
     * 
     * @type {City}
     * @memberof FindAllControlsResponse
     */
    'city': City;
    /**
     * 
     * @type {Project}
     * @memberof FindAllControlsResponse
     */
    'project': Project;
}
/**
 * 
 * @export
 * @interface FindAllFaqsResponse
 */
export interface FindAllFaqsResponse {
    /**
     * 
     * @type {string}
     * @memberof FindAllFaqsResponse
     */
    'faqCategory': FindAllFaqsResponseFaqCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof FindAllFaqsResponse
     */
    'faqId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllFaqsResponse
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllFaqsResponse
     */
    'answer': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllFaqsResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllFaqsResponse
     */
    'updatedAt': string;
}

export const FindAllFaqsResponseFaqCategoryEnum = {
    CustomerPortal: 'CustomerPortal',
    App: 'App'
} as const;

export type FindAllFaqsResponseFaqCategoryEnum = typeof FindAllFaqsResponseFaqCategoryEnum[keyof typeof FindAllFaqsResponseFaqCategoryEnum];

/**
 * 
 * @export
 * @interface FindAllFrameCompaniesResponse
 */
export interface FindAllFrameCompaniesResponse {
    /**
     * 
     * @type {string}
     * @memberof FindAllFrameCompaniesResponse
     */
    'frameCompanyId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllFrameCompaniesResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllFrameCompaniesResponse
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllFrameCompaniesResponse
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllFrameCompaniesResponse
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllFrameCompaniesResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllFrameCompaniesResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<BackgroundControlWithFiles>}
     * @memberof FindAllFrameCompaniesResponse
     */
    'BackgroundControl': Array<BackgroundControlWithFiles>;
    /**
     * 
     * @type {Array<BackgroundControlOrder>}
     * @memberof FindAllFrameCompaniesResponse
     */
    'BackgroundControlOrder': Array<BackgroundControlOrder>;
}
/**
 * 
 * @export
 * @interface FindAllID06CardCompetencesResponse
 */
export interface FindAllID06CardCompetencesResponse {
    /**
     * 
     * @type {string}
     * @memberof FindAllID06CardCompetencesResponse
     */
    'id06CardCompetenceId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllID06CardCompetencesResponse
     */
    'id06CardId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllID06CardCompetencesResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllID06CardCompetencesResponse
     */
    'group': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllID06CardCompetencesResponse
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllID06CardCompetencesResponse
     */
    'issuerCompanyRegistrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllID06CardCompetencesResponse
     */
    'issuerCompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllID06CardCompetencesResponse
     */
    'issuerCompanyNationality': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllID06CardCompetencesResponse
     */
    'issuedDate': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllID06CardCompetencesResponse
     */
    'validUntil': string;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllID06CardCompetencesResponse
     */
    'isRegulated': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllID06CardCompetencesResponse
     */
    'isEmployerGuarantee': boolean;
    /**
     * 
     * @type {string}
     * @memberof FindAllID06CardCompetencesResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllID06CardCompetencesResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface FindAllMyProjectsResponse
 */
export interface FindAllMyProjectsResponse {
    /**
     * 
     * @type {string}
     * @memberof FindAllMyProjectsResponse
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllMyProjectsResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllMyProjectsResponse
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllMyProjectsResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllMyProjectsResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllMyProjectsResponse
     */
    'siteManager': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllMyProjectsResponse
     */
    'mainContractor': string;
    /**
     * 
     * @type {number}
     * @memberof FindAllMyProjectsResponse
     */
    'deviationsRemaining': number;
}
/**
 * 
 * @export
 * @interface FindAllMyProjectsWithStatisticsResponse
 */
export interface FindAllMyProjectsWithStatisticsResponse {
    /**
     * 
     * @type {string}
     * @memberof FindAllMyProjectsWithStatisticsResponse
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllMyProjectsWithStatisticsResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllMyProjectsWithStatisticsResponse
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllMyProjectsWithStatisticsResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllMyProjectsWithStatisticsResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface FindAllNewsResponse
 */
export interface FindAllNewsResponse {
    /**
     * 
     * @type {string}
     * @memberof FindAllNewsResponse
     */
    'newsId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllNewsResponse
     */
    'header': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllNewsResponse
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllNewsResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllNewsResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface FindAllPersonnelFilesResponse
 */
export interface FindAllPersonnelFilesResponse {
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelFilesResponse
     */
    'personnelFileId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelFilesResponse
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelFilesResponse
     */
    'personnelId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelFilesResponse
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelFilesResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelFilesResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {any}
     * @memberof FindAllPersonnelFilesResponse
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface FindAllPersonnelResponse
 */
export interface FindAllPersonnelResponse {
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelResponse
     */
    'personnelId': string;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllPersonnelResponse
     */
    'isCheckedIn': boolean;
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelResponse
     */
    'workRegisterId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelResponse
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelResponse
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelResponse
     */
    'personalCodeNumber': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllPersonnelResponse
     */
    'secondWorkRegister': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllPersonnelResponse
     */
    'firstWorkRegister': boolean;
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelResponse
     */
    'birthdate': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelResponse
     */
    'arrivedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelResponse
     */
    'scannedEmail': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelResponse
     */
    'scannedAt': string | null;
    /**
     * 
     * @type {Company}
     * @memberof FindAllPersonnelResponse
     */
    'company': Company;
    /**
     * 
     * @type {string}
     * @memberof FindAllPersonnelResponse
     */
    'id06ReasonText': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllPersonnelResponse
     */
    'isScanned': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllPersonnelResponse
     */
    'isQuestionnaireDeviating': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllPersonnelResponse
     */
    'isID06CardDeviating': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllPersonnelResponse
     */
    'isID06CardOther': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllPersonnelResponse
     */
    'isID06CardInactive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllPersonnelResponse
     */
    'isID06CardDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllPersonnelResponse
     */
    'isID06CardInvalid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllPersonnelResponse
     */
    'hasID06CardComment': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllPersonnelResponse
     */
    'hasPersonnelQuestionnaireComment': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllPersonnelResponse
     */
    'isID06CardMissingCompetence': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindAllPersonnelResponse
     */
    'isControled': boolean;
}
/**
 * 
 * @export
 * @interface FindAllProjectsResponse
 */
export interface FindAllProjectsResponse {
    /**
     * 
     * @type {string}
     * @memberof FindAllProjectsResponse
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllProjectsResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllProjectsResponse
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllProjectsResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllProjectsResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface FindAllRedListedCompaniesResponse
 */
export interface FindAllRedListedCompaniesResponse {
    /**
     * 
     * @type {string}
     * @memberof FindAllRedListedCompaniesResponse
     */
    'redlistedCompanyId': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllRedListedCompaniesResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllRedListedCompaniesResponse
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllRedListedCompaniesResponse
     */
    'nationality': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllRedListedCompaniesResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindAllRedListedCompaniesResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface FindMyProjectWithControls
 */
export interface FindMyProjectWithControls {
    /**
     * 
     * @type {string}
     * @memberof FindMyProjectWithControls
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof FindMyProjectWithControls
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FindMyProjectWithControls
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof FindMyProjectWithControls
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindMyProjectWithControls
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<ControlWithCounterMeasures>}
     * @memberof FindMyProjectWithControls
     */
    'controls': Array<ControlWithCounterMeasures>;
}
/**
 * 
 * @export
 * @interface FindOneControlResponse
 */
export interface FindOneControlResponse {
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'inspectionCompany': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'inspectionResponsible': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'phoneNumber': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'inspectors': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'controlId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'creatorId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'cityId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'inspectionId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'assignmentDescription': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'summaryAndRecommendations': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'shellProtectionProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'shellProtectionConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'shellProtectionResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'shellProtectionActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'workEnvironmentProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'workEnvironmentConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'workEnvironmentResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'workEnvironmentActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'workRegisterProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'workRegisterConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'workRegisterResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'workRegisterActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'preApprovedSubcontractorProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'preApprovedSubcontractorConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'preApprovedSubcontractorResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'preApprovedSubcontractorActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'workPermitProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'workPermitConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'workPermitResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'workPermitActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'id06DeviationsProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'id06DeviationsConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'id06DeviationsResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'id06DeviationsActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'identificationProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'identificationConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'identificationResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'identificationActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'secondmentRegulationsProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'secondmentRegulationsConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'secondmentRegulationsResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'secondmentRegulationsActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'intoxicationProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'intoxicationConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'intoxicationResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'intoxicationActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'safetyEquipmentProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'safetyEquipmentConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'safetyEquipmentResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'safetyEquipmentActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'otherProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneControlResponse
     */
    'otherConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'otherResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'otherActions': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'creatorName': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'mainContractor': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'siteManager': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'gdprDataDeletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'reportStatedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'reportCompletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'completedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof FindOneControlResponse
     */
    'isCompleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'mainContractorCompanyId': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'siteManagerPersonnelId': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindOneControlResponse
     */
    'shellProtectionActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindOneControlResponse
     */
    'workEnvironmentActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindOneControlResponse
     */
    'workRegisterActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindOneControlResponse
     */
    'preApprovedSubcontractorActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindOneControlResponse
     */
    'workPermitActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindOneControlResponse
     */
    'id06DeviationsActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindOneControlResponse
     */
    'identificationActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindOneControlResponse
     */
    'secondmentRegulationsActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindOneControlResponse
     */
    'intoxicationActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindOneControlResponse
     */
    'safetyEquipmentActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindOneControlResponse
     */
    'otherActionsNeeded': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneControlResponse
     */
    'controlFile': string | null;
    /**
     * 
     * @type {Client}
     * @memberof FindOneControlResponse
     */
    'client': Client;
    /**
     * 
     * @type {City}
     * @memberof FindOneControlResponse
     */
    'city': City;
    /**
     * 
     * @type {Project}
     * @memberof FindOneControlResponse
     */
    'project': Project;
    /**
     * 
     * @type {WorkRegister}
     * @memberof FindOneControlResponse
     */
    'workRegister': WorkRegister;
    /**
     * 
     * @type {ControlStatistics}
     * @memberof FindOneControlResponse
     */
    'statistics': ControlStatistics;
    /**
     * 
     * @type {Array<CompanyWithPersonnel>}
     * @memberof FindOneControlResponse
     */
    'companies': Array<CompanyWithPersonnel>;
}
/**
 * 
 * @export
 * @interface FindOneFaqImage
 */
export interface FindOneFaqImage {
    /**
     * 
     * @type {string}
     * @memberof FindOneFaqImage
     */
    'faqFileId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneFaqImage
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneFaqImage
     */
    'faqId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneFaqImage
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneFaqImage
     */
    'updatedAt': string;
    /**
     * 
     * @type {any}
     * @memberof FindOneFaqImage
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface FindOneFaqResponse
 */
export interface FindOneFaqResponse {
    /**
     * 
     * @type {string}
     * @memberof FindOneFaqResponse
     */
    'faqCategory': FindOneFaqResponseFaqCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof FindOneFaqResponse
     */
    'faqId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneFaqResponse
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneFaqResponse
     */
    'answer': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneFaqResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneFaqResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<FindOneFaqImage>}
     * @memberof FindOneFaqResponse
     */
    'images': Array<FindOneFaqImage>;
}

export const FindOneFaqResponseFaqCategoryEnum = {
    CustomerPortal: 'CustomerPortal',
    App: 'App'
} as const;

export type FindOneFaqResponseFaqCategoryEnum = typeof FindOneFaqResponseFaqCategoryEnum[keyof typeof FindOneFaqResponseFaqCategoryEnum];

/**
 * 
 * @export
 * @interface FindOneID06CardResponse
 */
export interface FindOneID06CardResponse {
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'id06CardId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'personnelId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'cardNumber': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'statusCode': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'reasonCode': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'validFromDate': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'companyRegistrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'companyNationality': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'cardHolderNationality': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'cardHolderLastName': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'cardHolderFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'validCountry': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'cardHolderPersonalCodeNumber': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'cardHolderPhotoMimeType': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'cardHolderPhotoData': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneID06CardResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface FindOneNewsResponse
 */
export interface FindOneNewsResponse {
    /**
     * 
     * @type {string}
     * @memberof FindOneNewsResponse
     */
    'newsId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneNewsResponse
     */
    'header': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneNewsResponse
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneNewsResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneNewsResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface FindOnePersonnelQuestionnaireResponse
 */
export interface FindOnePersonnelQuestionnaireResponse {
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelQuestionnaireResponse
     */
    'personnelQuestionnaireId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelQuestionnaireResponse
     */
    'personnelId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelQuestionnaireResponse
     */
    'hasIdCard': FindOnePersonnelQuestionnaireResponseHasIdCardEnum;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelQuestionnaireResponse
     */
    'hasSafetyEquipment': FindOnePersonnelQuestionnaireResponseHasSafetyEquipmentEnum;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelQuestionnaireResponse
     */
    'canShowValidA1Certificate': FindOnePersonnelQuestionnaireResponseCanShowValidA1CertificateEnum;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelQuestionnaireResponse
     */
    'canShowValidWorkPermit': FindOnePersonnelQuestionnaireResponseCanShowValidWorkPermitEnum;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelQuestionnaireResponse
     */
    'hasSignsOfIntoxication': FindOnePersonnelQuestionnaireResponseHasSignsOfIntoxicationEnum;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelQuestionnaireResponse
     */
    'typeOfId': FindOnePersonnelQuestionnaireResponseTypeOfIdEnum;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelQuestionnaireResponse
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelQuestionnaireResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelQuestionnaireResponse
     */
    'updatedAt': string;
}

export const FindOnePersonnelQuestionnaireResponseHasIdCardEnum = {
    Yes: 'Yes',
    No: 'No',
    Unclear: 'Unclear'
} as const;

export type FindOnePersonnelQuestionnaireResponseHasIdCardEnum = typeof FindOnePersonnelQuestionnaireResponseHasIdCardEnum[keyof typeof FindOnePersonnelQuestionnaireResponseHasIdCardEnum];
export const FindOnePersonnelQuestionnaireResponseHasSafetyEquipmentEnum = {
    Yes: 'Yes',
    No: 'No'
} as const;

export type FindOnePersonnelQuestionnaireResponseHasSafetyEquipmentEnum = typeof FindOnePersonnelQuestionnaireResponseHasSafetyEquipmentEnum[keyof typeof FindOnePersonnelQuestionnaireResponseHasSafetyEquipmentEnum];
export const FindOnePersonnelQuestionnaireResponseCanShowValidA1CertificateEnum = {
    Yes: 'Yes',
    No: 'No',
    Unclear: 'Unclear',
    NotApplicable: 'NotApplicable'
} as const;

export type FindOnePersonnelQuestionnaireResponseCanShowValidA1CertificateEnum = typeof FindOnePersonnelQuestionnaireResponseCanShowValidA1CertificateEnum[keyof typeof FindOnePersonnelQuestionnaireResponseCanShowValidA1CertificateEnum];
export const FindOnePersonnelQuestionnaireResponseCanShowValidWorkPermitEnum = {
    Yes: 'Yes',
    No: 'No',
    Unclear: 'Unclear',
    NotApplicable: 'NotApplicable'
} as const;

export type FindOnePersonnelQuestionnaireResponseCanShowValidWorkPermitEnum = typeof FindOnePersonnelQuestionnaireResponseCanShowValidWorkPermitEnum[keyof typeof FindOnePersonnelQuestionnaireResponseCanShowValidWorkPermitEnum];
export const FindOnePersonnelQuestionnaireResponseHasSignsOfIntoxicationEnum = {
    Yes: 'Yes',
    No: 'No',
    Unclear: 'Unclear'
} as const;

export type FindOnePersonnelQuestionnaireResponseHasSignsOfIntoxicationEnum = typeof FindOnePersonnelQuestionnaireResponseHasSignsOfIntoxicationEnum[keyof typeof FindOnePersonnelQuestionnaireResponseHasSignsOfIntoxicationEnum];
export const FindOnePersonnelQuestionnaireResponseTypeOfIdEnum = {
    Passport: 'Passport',
    NationalId: 'NationalId',
    DriverLicense: 'DriverLicense',
    Skvid: 'SKVID'
} as const;

export type FindOnePersonnelQuestionnaireResponseTypeOfIdEnum = typeof FindOnePersonnelQuestionnaireResponseTypeOfIdEnum[keyof typeof FindOnePersonnelQuestionnaireResponseTypeOfIdEnum];

/**
 * 
 * @export
 * @interface FindOnePersonnelResponse
 */
export interface FindOnePersonnelResponse {
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelResponse
     */
    'personnelId': string;
    /**
     * 
     * @type {boolean}
     * @memberof FindOnePersonnelResponse
     */
    'isCheckedIn': boolean;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelResponse
     */
    'workRegisterId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelResponse
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelResponse
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelResponse
     */
    'personalCodeNumber': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindOnePersonnelResponse
     */
    'secondWorkRegister': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindOnePersonnelResponse
     */
    'firstWorkRegister': boolean;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelResponse
     */
    'birthdate': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelResponse
     */
    'arrivedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelResponse
     */
    'scannedEmail': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelResponse
     */
    'scannedAt': string | null;
    /**
     * 
     * @type {PersonnelQuestionnaire}
     * @memberof FindOnePersonnelResponse
     */
    'questionnaire'?: PersonnelQuestionnaire;
    /**
     * 
     * @type {ID06Card}
     * @memberof FindOnePersonnelResponse
     */
    'id06Card'?: ID06Card;
    /**
     * 
     * @type {Company}
     * @memberof FindOnePersonnelResponse
     */
    'company': Company;
    /**
     * 
     * @type {string}
     * @memberof FindOnePersonnelResponse
     */
    'id06ReasonText': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FindOnePersonnelResponse
     */
    'isScanned': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindOnePersonnelResponse
     */
    'isQuestionnaireDeviating': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindOnePersonnelResponse
     */
    'isID06CardDeviating': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindOnePersonnelResponse
     */
    'isID06CardOther': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindOnePersonnelResponse
     */
    'isID06CardInactive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindOnePersonnelResponse
     */
    'isID06CardDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindOnePersonnelResponse
     */
    'isID06CardInvalid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindOnePersonnelResponse
     */
    'hasID06CardComment': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindOnePersonnelResponse
     */
    'hasPersonnelQuestionnaireComment': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FindOnePersonnelResponse
     */
    'isID06CardMissingCompetence': boolean;
}
/**
 * 
 * @export
 * @interface FindOneWorkRegisterResponse
 */
export interface FindOneWorkRegisterResponse {
    /**
     * 
     * @type {string}
     * @memberof FindOneWorkRegisterResponse
     */
    'workRegisterId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneWorkRegisterResponse
     */
    'controlId': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneWorkRegisterResponse
     */
    'businessType': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneWorkRegisterResponse
     */
    'createdBySoftware': string | null;
    /**
     * 
     * @type {number}
     * @memberof FindOneWorkRegisterResponse
     */
    'numUploads': number;
    /**
     * 
     * @type {string}
     * @memberof FindOneWorkRegisterResponse
     */
    'softwareVersion': string | null;
    /**
     * 
     * @type {string}
     * @memberof FindOneWorkRegisterResponse
     */
    'registeredAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneWorkRegisterResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FindOneWorkRegisterResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {FindOneWorkRegisterResponseCount}
     * @memberof FindOneWorkRegisterResponse
     */
    '_count': FindOneWorkRegisterResponseCount;
}
/**
 * 
 * @export
 * @interface FindOneWorkRegisterResponseCount
 */
export interface FindOneWorkRegisterResponseCount {
    /**
     * 
     * @type {number}
     * @memberof FindOneWorkRegisterResponseCount
     */
    'personnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindOneWorkRegisterResponseCount
     */
    'scannedPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindOneWorkRegisterResponseCount
     */
    'deviatingPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindOneWorkRegisterResponseCount
     */
    'controledPersonnel': number;
}
/**
 * 
 * @export
 * @interface FindSummaryCurrentControl
 */
export interface FindSummaryCurrentControl {
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'percentageOfCheckedInAndControlledPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'percentageOfAdditionalPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'percentageOfNotControlledPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'percentageOfPersonnelWithIdCardDeviations': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'percentageOfPersonnelWithInvalidIdentification': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'percentageOfPersonnelWithoutSafeConstructionTraining': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'percentageOfPersonnelWithoutSafetyEquipment': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'percentageOfPersonnelWithoutWorkPermit': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'percentageOfPersonnelWithoutCertificate': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'numberOfPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'numberOfCheckedInPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'numberOfCheckedInPersonnelEnd': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'numberOfControlledPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'numberOfCheckedInAndControlledPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'numberOfAdditionalPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'numberOfNotControlledPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'numberOfPersonnelWithIdCardDeviations': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'numberOfPersonnelWithInvalidIdentification': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'numberOfPersonnelWithoutSafeConstructionTraining': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'numberOfPersonnelWithoutSafetyEquipment': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'numberOfPersonnelWithoutWorkPermit': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryCurrentControl
     */
    'numberOfPersonnelWithoutCertificate': number;
}
/**
 * 
 * @export
 * @interface FindSummaryPercentages
 */
export interface FindSummaryPercentages {
    /**
     * 
     * @type {number}
     * @memberof FindSummaryPercentages
     */
    'percentageOfCheckedInAndControlledPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryPercentages
     */
    'percentageOfAdditionalPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryPercentages
     */
    'percentageOfNotControlledPersonnel': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryPercentages
     */
    'percentageOfPersonnelWithIdCardDeviations': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryPercentages
     */
    'percentageOfPersonnelWithInvalidIdentification': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryPercentages
     */
    'percentageOfPersonnelWithoutSafeConstructionTraining': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryPercentages
     */
    'percentageOfPersonnelWithoutSafetyEquipment': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryPercentages
     */
    'percentageOfPersonnelWithoutWorkPermit': number;
    /**
     * 
     * @type {number}
     * @memberof FindSummaryPercentages
     */
    'percentageOfPersonnelWithoutCertificate': number;
}
/**
 * 
 * @export
 * @interface GetClientOverviewStatisticsDto
 */
export interface GetClientOverviewStatisticsDto {
    /**
     * 
     * @type {BackgroundResults}
     * @memberof GetClientOverviewStatisticsDto
     */
    'backgroundControls': BackgroundResults;
    /**
     * 
     * @type {Amount}
     * @memberof GetClientOverviewStatisticsDto
     */
    'controls': Amount;
    /**
     * 
     * @type {Amount}
     * @memberof GetClientOverviewStatisticsDto
     */
    'openDeviations': Amount;
}
/**
 * 
 * @export
 * @interface ID06Card
 */
export interface ID06Card {
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'id06CardId': string;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'personnelId': string;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'cardNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'statusCode': string;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'reasonCode': string;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'validFromDate': string;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'companyRegistrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'companyNationality': string;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'cardHolderNationality': string;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'cardHolderLastName': string;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'cardHolderFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'validCountry': string;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'cardHolderPersonalCodeNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'cardHolderPhotoMimeType': string;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'cardHolderPhotoData': string;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ID06Card
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'uploadedById': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'originalName': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'uniqueName': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'mimetype': string;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    'size': number;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface Personnel
 */
export interface Personnel {
    /**
     * 
     * @type {string}
     * @memberof Personnel
     */
    'personnelId': string;
    /**
     * 
     * @type {boolean}
     * @memberof Personnel
     */
    'isCheckedIn': boolean;
    /**
     * 
     * @type {string}
     * @memberof Personnel
     */
    'workRegisterId': string;
    /**
     * 
     * @type {string}
     * @memberof Personnel
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof Personnel
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof Personnel
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof Personnel
     */
    'personalCodeNumber': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Personnel
     */
    'secondWorkRegister': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Personnel
     */
    'firstWorkRegister': boolean;
    /**
     * 
     * @type {string}
     * @memberof Personnel
     */
    'birthdate': string | null;
    /**
     * 
     * @type {string}
     * @memberof Personnel
     */
    'arrivedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof Personnel
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Personnel
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Personnel
     */
    'scannedEmail': string | null;
    /**
     * 
     * @type {string}
     * @memberof Personnel
     */
    'scannedAt': string | null;
}
/**
 * 
 * @export
 * @interface PersonnelQuestionnaire
 */
export interface PersonnelQuestionnaire {
    /**
     * 
     * @type {string}
     * @memberof PersonnelQuestionnaire
     */
    'personnelQuestionnaireId': string;
    /**
     * 
     * @type {string}
     * @memberof PersonnelQuestionnaire
     */
    'personnelId': string;
    /**
     * 
     * @type {string}
     * @memberof PersonnelQuestionnaire
     */
    'hasIdCard': PersonnelQuestionnaireHasIdCardEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonnelQuestionnaire
     */
    'hasSafetyEquipment': PersonnelQuestionnaireHasSafetyEquipmentEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonnelQuestionnaire
     */
    'canShowValidA1Certificate': PersonnelQuestionnaireCanShowValidA1CertificateEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonnelQuestionnaire
     */
    'canShowValidWorkPermit': PersonnelQuestionnaireCanShowValidWorkPermitEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonnelQuestionnaire
     */
    'hasSignsOfIntoxication': PersonnelQuestionnaireHasSignsOfIntoxicationEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonnelQuestionnaire
     */
    'typeOfId': PersonnelQuestionnaireTypeOfIdEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonnelQuestionnaire
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonnelQuestionnaire
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PersonnelQuestionnaire
     */
    'updatedAt': string;
}

export const PersonnelQuestionnaireHasIdCardEnum = {
    Yes: 'Yes',
    No: 'No',
    Unclear: 'Unclear'
} as const;

export type PersonnelQuestionnaireHasIdCardEnum = typeof PersonnelQuestionnaireHasIdCardEnum[keyof typeof PersonnelQuestionnaireHasIdCardEnum];
export const PersonnelQuestionnaireHasSafetyEquipmentEnum = {
    Yes: 'Yes',
    No: 'No'
} as const;

export type PersonnelQuestionnaireHasSafetyEquipmentEnum = typeof PersonnelQuestionnaireHasSafetyEquipmentEnum[keyof typeof PersonnelQuestionnaireHasSafetyEquipmentEnum];
export const PersonnelQuestionnaireCanShowValidA1CertificateEnum = {
    Yes: 'Yes',
    No: 'No',
    Unclear: 'Unclear',
    NotApplicable: 'NotApplicable'
} as const;

export type PersonnelQuestionnaireCanShowValidA1CertificateEnum = typeof PersonnelQuestionnaireCanShowValidA1CertificateEnum[keyof typeof PersonnelQuestionnaireCanShowValidA1CertificateEnum];
export const PersonnelQuestionnaireCanShowValidWorkPermitEnum = {
    Yes: 'Yes',
    No: 'No',
    Unclear: 'Unclear',
    NotApplicable: 'NotApplicable'
} as const;

export type PersonnelQuestionnaireCanShowValidWorkPermitEnum = typeof PersonnelQuestionnaireCanShowValidWorkPermitEnum[keyof typeof PersonnelQuestionnaireCanShowValidWorkPermitEnum];
export const PersonnelQuestionnaireHasSignsOfIntoxicationEnum = {
    Yes: 'Yes',
    No: 'No',
    Unclear: 'Unclear'
} as const;

export type PersonnelQuestionnaireHasSignsOfIntoxicationEnum = typeof PersonnelQuestionnaireHasSignsOfIntoxicationEnum[keyof typeof PersonnelQuestionnaireHasSignsOfIntoxicationEnum];
export const PersonnelQuestionnaireTypeOfIdEnum = {
    Passport: 'Passport',
    NationalId: 'NationalId',
    DriverLicense: 'DriverLicense',
    Skvid: 'SKVID'
} as const;

export type PersonnelQuestionnaireTypeOfIdEnum = typeof PersonnelQuestionnaireTypeOfIdEnum[keyof typeof PersonnelQuestionnaireTypeOfIdEnum];

/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface RedListedCompany
 */
export interface RedListedCompany {
    /**
     * 
     * @type {string}
     * @memberof RedListedCompany
     */
    'redlistedCompanyId': string;
    /**
     * 
     * @type {string}
     * @memberof RedListedCompany
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RedListedCompany
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof RedListedCompany
     */
    'nationality': string;
    /**
     * 
     * @type {string}
     * @memberof RedListedCompany
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof RedListedCompany
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface UpdateClientDto
 */
export interface UpdateClientDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateClientDto
     */
    'registrationNumber': string;
}
/**
 * 
 * @export
 * @interface UpdateCompanyDto
 */
export interface UpdateCompanyDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyDto
     */
    'isPreQualified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'nationality'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyDto
     */
    'hasReportingObligation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyDto
     */
    'isReportingObligationFulfilled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'comment'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyDto
     */
    'isPaused'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateCompanyResponse
 */
export interface UpdateCompanyResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyResponse
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyResponse
     */
    'controlId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyResponse
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyResponse
     */
    'nationality': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyResponse
     */
    'isPreQualified': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyResponse
     */
    'hasReportingObligation': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyResponse
     */
    'isReportingObligationFulfilled': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyResponse
     */
    'isPaused': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyResponse
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface UpdateControlDto
 */
export interface UpdateControlDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'inspectionCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'inspectionResponsible'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'inspectors'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'project'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'client'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'mainContractor'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'mainContractorCompanyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'siteManager'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'siteManagerPersonnelId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'completedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'assignmentDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'summaryAndRecommendations'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'shellProtectionProbability'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'shellProtectionConsequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'shellProtectionResult'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'shellProtectionActions'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'workEnvironmentProbability'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'workEnvironmentConsequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'workEnvironmentResult'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'workEnvironmentActions'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'workRegisterProbability'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'workRegisterConsequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'workRegisterResult'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'workRegisterActions'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'preApprovedSubcontractorProbability'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'preApprovedSubcontractorConsequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'preApprovedSubcontractorResult'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'preApprovedSubcontractorActions'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'workPermitProbability'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'workPermitConsequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'workPermitResult'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'workPermitActions'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'id06DeviationsProbability'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'id06DeviationsConsequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'id06DeviationsResult'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'id06DeviationsActions'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'identificationProbability'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'identificationConsequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'identificationResult'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'identificationActions'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'secondmentRegulationsProbability'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'secondmentRegulationsConsequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'secondmentRegulationsResult'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'secondmentRegulationsActions'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'intoxicationProbability'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'intoxicationConsequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'intoxicationResult'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'intoxicationActions'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'safetyEquipmentProbability'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'safetyEquipmentConsequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'safetyEquipmentResult'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'safetyEquipmentActions'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'otherProbability'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlDto
     */
    'otherConsequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'otherResult'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlDto
     */
    'otherActions'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlDto
     */
    'isCompleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlDto
     */
    'shellProtectionActionsNeeded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlDto
     */
    'workEnvironmentActionsNeeded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlDto
     */
    'workRegisterActionsNeeded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlDto
     */
    'preApprovedSubcontractorActionsNeeded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlDto
     */
    'workPermitActionsNeeded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlDto
     */
    'id06DeviationsActionsNeeded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlDto
     */
    'identificationActionsNeeded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlDto
     */
    'secondmentRegulationsActionsNeeded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlDto
     */
    'intoxicationActionsNeeded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlDto
     */
    'safetyEquipmentActionsNeeded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlDto
     */
    'otherActionsNeeded'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateControlFileDto
 */
export interface UpdateControlFileDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateControlFileDto
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface UpdateControlFileResponse
 */
export interface UpdateControlFileResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateControlFileResponse
     */
    'controlFileId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlFileResponse
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlFileResponse
     */
    'controlId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlFileResponse
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlFileResponse
     */
    'type': UpdateControlFileResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlFileResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlFileResponse
     */
    'updatedAt': string;
}

export const UpdateControlFileResponseTypeEnum = {
    WorkEnvironment: 'WorkEnvironment',
    ShellProtection: 'ShellProtection'
} as const;

export type UpdateControlFileResponseTypeEnum = typeof UpdateControlFileResponseTypeEnum[keyof typeof UpdateControlFileResponseTypeEnum];

/**
 * 
 * @export
 * @interface UpdateControlResponse
 */
export interface UpdateControlResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'inspectionCompany': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'inspectionResponsible': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'phoneNumber': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'inspectors': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'controlId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'creatorId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'cityId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'inspectionId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'assignmentDescription': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'summaryAndRecommendations': string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'shellProtectionProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'shellProtectionConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'shellProtectionResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'shellProtectionActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'workEnvironmentProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'workEnvironmentConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'workEnvironmentResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'workEnvironmentActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'workRegisterProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'workRegisterConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'workRegisterResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'workRegisterActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'preApprovedSubcontractorProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'preApprovedSubcontractorConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'preApprovedSubcontractorResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'preApprovedSubcontractorActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'workPermitProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'workPermitConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'workPermitResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'workPermitActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'id06DeviationsProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'id06DeviationsConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'id06DeviationsResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'id06DeviationsActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'identificationProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'identificationConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'identificationResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'identificationActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'secondmentRegulationsProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'secondmentRegulationsConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'secondmentRegulationsResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'secondmentRegulationsActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'intoxicationProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'intoxicationConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'intoxicationResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'intoxicationActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'safetyEquipmentProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'safetyEquipmentConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'safetyEquipmentResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'safetyEquipmentActions': string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'otherProbability': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateControlResponse
     */
    'otherConsequence': number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'otherResult': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'otherActions': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'creatorName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'mainContractor': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'siteManager': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'gdprDataDeletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'reportStatedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'reportCompletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'completedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlResponse
     */
    'isCompleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'mainContractorCompanyId': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'siteManagerPersonnelId': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlResponse
     */
    'shellProtectionActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlResponse
     */
    'workEnvironmentActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlResponse
     */
    'workRegisterActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlResponse
     */
    'preApprovedSubcontractorActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlResponse
     */
    'workPermitActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlResponse
     */
    'id06DeviationsActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlResponse
     */
    'identificationActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlResponse
     */
    'secondmentRegulationsActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlResponse
     */
    'intoxicationActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlResponse
     */
    'safetyEquipmentActionsNeeded': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateControlResponse
     */
    'otherActionsNeeded': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateControlResponse
     */
    'controlFile': string | null;
}
/**
 * 
 * @export
 * @interface UpdateFaqDto
 */
export interface UpdateFaqDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateFaqDto
     */
    'faqCategory'?: UpdateFaqDtoFaqCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateFaqDto
     */
    'question'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFaqDto
     */
    'answer'?: string;
}

export const UpdateFaqDtoFaqCategoryEnum = {
    CustomerPortal: 'CustomerPortal',
    App: 'App'
} as const;

export type UpdateFaqDtoFaqCategoryEnum = typeof UpdateFaqDtoFaqCategoryEnum[keyof typeof UpdateFaqDtoFaqCategoryEnum];

/**
 * 
 * @export
 * @interface UpdateFaqResponse
 */
export interface UpdateFaqResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateFaqResponse
     */
    'faqCategory': UpdateFaqResponseFaqCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateFaqResponse
     */
    'faqId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFaqResponse
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFaqResponse
     */
    'answer': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFaqResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFaqResponse
     */
    'updatedAt': string;
}

export const UpdateFaqResponseFaqCategoryEnum = {
    CustomerPortal: 'CustomerPortal',
    App: 'App'
} as const;

export type UpdateFaqResponseFaqCategoryEnum = typeof UpdateFaqResponseFaqCategoryEnum[keyof typeof UpdateFaqResponseFaqCategoryEnum];

/**
 * 
 * @export
 * @interface UpdateID06CardDto
 */
export interface UpdateID06CardDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardDto
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface UpdateID06CardResponse
 */
export interface UpdateID06CardResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'id06CardId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'personnelId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'cardNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'statusCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'reasonCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'validFromDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'companyRegistrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'companyNationality': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'cardHolderNationality': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'cardHolderLastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'cardHolderFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'validCountry': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'cardHolderPersonalCodeNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'cardHolderPhotoMimeType': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'cardHolderPhotoData': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateID06CardResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface UpdatePersonnelFileDto
 */
export interface UpdatePersonnelFileDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonnelFileDto
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface UpdatePersonnelFileResponse
 */
export interface UpdatePersonnelFileResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonnelFileResponse
     */
    'personnelFileId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonnelFileResponse
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonnelFileResponse
     */
    'personnelId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonnelFileResponse
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonnelFileResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonnelFileResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface UploadFileResponse
 */
export interface UploadFileResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'uploadedById': string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'originalName': string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'uniqueName': string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'mimetype': string;
    /**
     * 
     * @type {number}
     * @memberof UploadFileResponse
     */
    'size': number;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface UpsertID06CardDto
 */
export interface UpsertID06CardDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof UpsertID06CardDto
     */
    'data': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof UpsertID06CardDto
     */
    'sw1': number;
    /**
     * 
     * @type {number}
     * @memberof UpsertID06CardDto
     */
    'sw2': number;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardDto
     */
    'cardNumber'?: string;
}
/**
 * 
 * @export
 * @interface UpsertID06CardResponse
 */
export interface UpsertID06CardResponse {
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'id06CardId': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'personnelId': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'cardNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'statusCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'reasonCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'validFromDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'companyRegistrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'companyNationality': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'cardHolderNationality': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'cardHolderLastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'cardHolderFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'validCountry': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'cardHolderPersonalCodeNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'cardHolderPhotoMimeType': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'cardHolderPhotoData': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertID06CardResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface UpsertPersonnelDto
 */
export interface UpsertPersonnelDto {
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelDto
     */
    'personalCodeNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelDto
     */
    'birthdate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelDto
     */
    'arrivedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelDto
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelDto
     */
    'companyRegistrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelDto
     */
    'companyNationality'?: string;
}
/**
 * 
 * @export
 * @interface UpsertPersonnelQuestionnaireDto
 */
export interface UpsertPersonnelQuestionnaireDto {
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelQuestionnaireDto
     */
    'hasIdCard'?: UpsertPersonnelQuestionnaireDtoHasIdCardEnum;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelQuestionnaireDto
     */
    'typeOfId'?: UpsertPersonnelQuestionnaireDtoTypeOfIdEnum;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelQuestionnaireDto
     */
    'hasSafetyEquipment'?: UpsertPersonnelQuestionnaireDtoHasSafetyEquipmentEnum;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelQuestionnaireDto
     */
    'canShowValidA1Certificate'?: UpsertPersonnelQuestionnaireDtoCanShowValidA1CertificateEnum;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelQuestionnaireDto
     */
    'canShowValidWorkPermit'?: UpsertPersonnelQuestionnaireDtoCanShowValidWorkPermitEnum;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelQuestionnaireDto
     */
    'hasSignsOfIntoxication'?: UpsertPersonnelQuestionnaireDtoHasSignsOfIntoxicationEnum;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelQuestionnaireDto
     */
    'comment'?: string;
}

export const UpsertPersonnelQuestionnaireDtoHasIdCardEnum = {
    Yes: 'Yes',
    No: 'No',
    Unclear: 'Unclear'
} as const;

export type UpsertPersonnelQuestionnaireDtoHasIdCardEnum = typeof UpsertPersonnelQuestionnaireDtoHasIdCardEnum[keyof typeof UpsertPersonnelQuestionnaireDtoHasIdCardEnum];
export const UpsertPersonnelQuestionnaireDtoTypeOfIdEnum = {
    Passport: 'Passport',
    NationalId: 'NationalId',
    DriverLicense: 'DriverLicense',
    Skvid: 'SKVID'
} as const;

export type UpsertPersonnelQuestionnaireDtoTypeOfIdEnum = typeof UpsertPersonnelQuestionnaireDtoTypeOfIdEnum[keyof typeof UpsertPersonnelQuestionnaireDtoTypeOfIdEnum];
export const UpsertPersonnelQuestionnaireDtoHasSafetyEquipmentEnum = {
    Yes: 'Yes',
    No: 'No'
} as const;

export type UpsertPersonnelQuestionnaireDtoHasSafetyEquipmentEnum = typeof UpsertPersonnelQuestionnaireDtoHasSafetyEquipmentEnum[keyof typeof UpsertPersonnelQuestionnaireDtoHasSafetyEquipmentEnum];
export const UpsertPersonnelQuestionnaireDtoCanShowValidA1CertificateEnum = {
    Yes: 'Yes',
    No: 'No',
    Unclear: 'Unclear',
    NotApplicable: 'NotApplicable'
} as const;

export type UpsertPersonnelQuestionnaireDtoCanShowValidA1CertificateEnum = typeof UpsertPersonnelQuestionnaireDtoCanShowValidA1CertificateEnum[keyof typeof UpsertPersonnelQuestionnaireDtoCanShowValidA1CertificateEnum];
export const UpsertPersonnelQuestionnaireDtoCanShowValidWorkPermitEnum = {
    Yes: 'Yes',
    No: 'No',
    Unclear: 'Unclear',
    NotApplicable: 'NotApplicable'
} as const;

export type UpsertPersonnelQuestionnaireDtoCanShowValidWorkPermitEnum = typeof UpsertPersonnelQuestionnaireDtoCanShowValidWorkPermitEnum[keyof typeof UpsertPersonnelQuestionnaireDtoCanShowValidWorkPermitEnum];
export const UpsertPersonnelQuestionnaireDtoHasSignsOfIntoxicationEnum = {
    Yes: 'Yes',
    No: 'No',
    Unclear: 'Unclear'
} as const;

export type UpsertPersonnelQuestionnaireDtoHasSignsOfIntoxicationEnum = typeof UpsertPersonnelQuestionnaireDtoHasSignsOfIntoxicationEnum[keyof typeof UpsertPersonnelQuestionnaireDtoHasSignsOfIntoxicationEnum];

/**
 * 
 * @export
 * @interface UpsertPersonnelQuestionnaireResponse
 */
export interface UpsertPersonnelQuestionnaireResponse {
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelQuestionnaireResponse
     */
    'personnelQuestionnaireId': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelQuestionnaireResponse
     */
    'personnelId': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelQuestionnaireResponse
     */
    'hasIdCard': UpsertPersonnelQuestionnaireResponseHasIdCardEnum;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelQuestionnaireResponse
     */
    'hasSafetyEquipment': UpsertPersonnelQuestionnaireResponseHasSafetyEquipmentEnum;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelQuestionnaireResponse
     */
    'canShowValidA1Certificate': UpsertPersonnelQuestionnaireResponseCanShowValidA1CertificateEnum;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelQuestionnaireResponse
     */
    'canShowValidWorkPermit': UpsertPersonnelQuestionnaireResponseCanShowValidWorkPermitEnum;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelQuestionnaireResponse
     */
    'hasSignsOfIntoxication': UpsertPersonnelQuestionnaireResponseHasSignsOfIntoxicationEnum;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelQuestionnaireResponse
     */
    'typeOfId': UpsertPersonnelQuestionnaireResponseTypeOfIdEnum;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelQuestionnaireResponse
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelQuestionnaireResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelQuestionnaireResponse
     */
    'updatedAt': string;
}

export const UpsertPersonnelQuestionnaireResponseHasIdCardEnum = {
    Yes: 'Yes',
    No: 'No',
    Unclear: 'Unclear'
} as const;

export type UpsertPersonnelQuestionnaireResponseHasIdCardEnum = typeof UpsertPersonnelQuestionnaireResponseHasIdCardEnum[keyof typeof UpsertPersonnelQuestionnaireResponseHasIdCardEnum];
export const UpsertPersonnelQuestionnaireResponseHasSafetyEquipmentEnum = {
    Yes: 'Yes',
    No: 'No'
} as const;

export type UpsertPersonnelQuestionnaireResponseHasSafetyEquipmentEnum = typeof UpsertPersonnelQuestionnaireResponseHasSafetyEquipmentEnum[keyof typeof UpsertPersonnelQuestionnaireResponseHasSafetyEquipmentEnum];
export const UpsertPersonnelQuestionnaireResponseCanShowValidA1CertificateEnum = {
    Yes: 'Yes',
    No: 'No',
    Unclear: 'Unclear',
    NotApplicable: 'NotApplicable'
} as const;

export type UpsertPersonnelQuestionnaireResponseCanShowValidA1CertificateEnum = typeof UpsertPersonnelQuestionnaireResponseCanShowValidA1CertificateEnum[keyof typeof UpsertPersonnelQuestionnaireResponseCanShowValidA1CertificateEnum];
export const UpsertPersonnelQuestionnaireResponseCanShowValidWorkPermitEnum = {
    Yes: 'Yes',
    No: 'No',
    Unclear: 'Unclear',
    NotApplicable: 'NotApplicable'
} as const;

export type UpsertPersonnelQuestionnaireResponseCanShowValidWorkPermitEnum = typeof UpsertPersonnelQuestionnaireResponseCanShowValidWorkPermitEnum[keyof typeof UpsertPersonnelQuestionnaireResponseCanShowValidWorkPermitEnum];
export const UpsertPersonnelQuestionnaireResponseHasSignsOfIntoxicationEnum = {
    Yes: 'Yes',
    No: 'No',
    Unclear: 'Unclear'
} as const;

export type UpsertPersonnelQuestionnaireResponseHasSignsOfIntoxicationEnum = typeof UpsertPersonnelQuestionnaireResponseHasSignsOfIntoxicationEnum[keyof typeof UpsertPersonnelQuestionnaireResponseHasSignsOfIntoxicationEnum];
export const UpsertPersonnelQuestionnaireResponseTypeOfIdEnum = {
    Passport: 'Passport',
    NationalId: 'NationalId',
    DriverLicense: 'DriverLicense',
    Skvid: 'SKVID'
} as const;

export type UpsertPersonnelQuestionnaireResponseTypeOfIdEnum = typeof UpsertPersonnelQuestionnaireResponseTypeOfIdEnum[keyof typeof UpsertPersonnelQuestionnaireResponseTypeOfIdEnum];

/**
 * 
 * @export
 * @interface UpsertPersonnelResponse
 */
export interface UpsertPersonnelResponse {
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelResponse
     */
    'personnelId': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertPersonnelResponse
     */
    'isCheckedIn': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelResponse
     */
    'workRegisterId': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelResponse
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelResponse
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelResponse
     */
    'personalCodeNumber': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertPersonnelResponse
     */
    'secondWorkRegister': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertPersonnelResponse
     */
    'firstWorkRegister': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelResponse
     */
    'birthdate': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelResponse
     */
    'arrivedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelResponse
     */
    'scannedEmail': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertPersonnelResponse
     */
    'scannedAt': string | null;
}
/**
 * 
 * @export
 * @interface WorkRegister
 */
export interface WorkRegister {
    /**
     * 
     * @type {string}
     * @memberof WorkRegister
     */
    'workRegisterId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkRegister
     */
    'controlId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkRegister
     */
    'businessType': string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkRegister
     */
    'createdBySoftware': string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkRegister
     */
    'numUploads': number;
    /**
     * 
     * @type {string}
     * @memberof WorkRegister
     */
    'softwareVersion': string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkRegister
     */
    'registeredAt': string;
    /**
     * 
     * @type {string}
     * @memberof WorkRegister
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof WorkRegister
     */
    'updatedAt': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create control
         * @param {CreateBackgroundControlDto} createBackgroundControlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundControlsCreate: async (createBackgroundControlDto: CreateBackgroundControlDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBackgroundControlDto' is not null or undefined
            assertParamExists('backgroundControlsCreate', 'createBackgroundControlDto', createBackgroundControlDto)
            const localVarPath = `/api/v1/background-controls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBackgroundControlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create control
         * @param {CreateBackgroundControlFrameDto} createBackgroundControlFrameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundControlsCreateFrame: async (createBackgroundControlFrameDto: CreateBackgroundControlFrameDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBackgroundControlFrameDto' is not null or undefined
            assertParamExists('backgroundControlsCreateFrame', 'createBackgroundControlFrameDto', createBackgroundControlFrameDto)
            const localVarPath = `/api/v1/background-controls/order-frame`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBackgroundControlFrameDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one control signed url
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundControlsGetControlFile: async (controlId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('backgroundControlsGetControlFile', 'controlId', controlId)
            const localVarPath = `/api/v1/background-controls/controle-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (controlId !== undefined) {
                localVarQueryParameter['controlId'] = controlId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get specific background control order
         * @param {string} [companyId] 
         * @param {string} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundControlsOrderGet: async (companyId?: string, projectId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/background-controls-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Order control
         * @param {CreateBackgroundControlOrderDto} createBackgroundControlOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundControlsOrderOrder: async (createBackgroundControlOrderDto: CreateBackgroundControlOrderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBackgroundControlOrderDto' is not null or undefined
            assertParamExists('backgroundControlsOrderOrder', 'createBackgroundControlOrderDto', createBackgroundControlOrderDto)
            const localVarPath = `/api/v1/background-controls-order/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBackgroundControlOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Order control of frame company
         * @param {CreateBackgroundControlOrderFrameDto} createBackgroundControlOrderFrameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundControlsOrderOrderFrame: async (createBackgroundControlOrderFrameDto: CreateBackgroundControlOrderFrameDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBackgroundControlOrderFrameDto' is not null or undefined
            assertParamExists('backgroundControlsOrderOrderFrame', 'createBackgroundControlOrderFrameDto', createBackgroundControlOrderFrameDto)
            const localVarPath = `/api/v1/background-controls-order/order-frame`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBackgroundControlOrderFrameDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all cities
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        citiesFindAll: async (search?: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get client overview statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientOverviewStatisticsFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/client-overview-statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all clients
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsFindAll: async (search?: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find specified client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsFindOne: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('clientsFindOne', 'clientId', clientId)
            const localVarPath = `/api/v1/clients/{clientId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update client by clientId
         * @param {string} clientId 
         * @param {UpdateClientDto} updateClientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsUpdate: async (clientId: string, updateClientDto: UpdateClientDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('clientsUpdate', 'clientId', clientId)
            // verify required parameter 'updateClientDto' is not null or undefined
            assertParamExists('clientsUpdate', 'updateClientDto', updateClientDto)
            const localVarPath = `/api/v1/clients/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateClientDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all companies on my clients
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesFindAllOnMyClients: async (search?: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companies/my-companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all companies on my clients
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesFindAllOnMyClientsWithStatistics: async (search?: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companies/my-companies/with-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find company by inspectionId
         * @param {string} inspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesFindByInspectionId: async (inspectionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inspectionId' is not null or undefined
            assertParamExists('companiesFindByInspectionId', 'inspectionId', inspectionId)
            const localVarPath = `/api/v1/companies/control/inspection/{inspectionId}`
                .replace(`{${"inspectionId"}}`, encodeURIComponent(String(inspectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update company by companyId
         * @param {string} companyId 
         * @param {UpdateCompanyDto} updateCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesUpdate: async (companyId: string, updateCompanyDto: UpdateCompanyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companiesUpdate', 'companyId', companyId)
            // verify required parameter 'updateCompanyDto' is not null or undefined
            assertParamExists('companiesUpdate', 'updateCompanyDto', updateCompanyDto)
            const localVarPath = `/api/v1/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find company statistics
         * @param {string} controlId 
         * @param {string} sort 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyStatisticsFindAll: async (controlId: string, sort: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('companyStatisticsFindAll', 'controlId', controlId)
            // verify required parameter 'sort' is not null or undefined
            assertParamExists('companyStatisticsFindAll', 'sort', sort)
            const localVarPath = `/api/v1/company-statistics/{controlId}`
                .replace(`{${"controlId"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find company statistics for companies
         * @param {FetchCompanies} fetchCompanies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyStatisticsFindCompanies: async (fetchCompanies: FetchCompanies, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fetchCompanies' is not null or undefined
            assertParamExists('companyStatisticsFindCompanies', 'fetchCompanies', fetchCompanies)
            const localVarPath = `/api/v1/company-statistics/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fetchCompanies, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find company statistics average companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyStatisticsGetCompanyAverage: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/company-statistics/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete controls file
         * @param {string} controlFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlFilesDelete: async (controlFileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlFileId' is not null or undefined
            assertParamExists('controlFilesDelete', 'controlFileId', controlFileId)
            const localVarPath = `/api/v1/control-files/{controlFileId}`
                .replace(`{${"controlFileId"}}`, encodeURIComponent(String(controlFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update control file comment
         * @param {string} controlFileId 
         * @param {UpdateControlFileDto} updateControlFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlFilesFindOne: async (controlFileId: string, updateControlFileDto: UpdateControlFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlFileId' is not null or undefined
            assertParamExists('controlFilesFindOne', 'controlFileId', controlFileId)
            // verify required parameter 'updateControlFileDto' is not null or undefined
            assertParamExists('controlFilesFindOne', 'updateControlFileDto', updateControlFileDto)
            const localVarPath = `/api/v1/control-files/{controlFileId}`
                .replace(`{${"controlFileId"}}`, encodeURIComponent(String(controlFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateControlFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find control statistics
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStatisticsFindSummary: async (controlId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('controlStatisticsFindSummary', 'controlId', controlId)
            const localVarPath = `/api/v1/control-statistics/{controlId}`
                .replace(`{${"controlId"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find project statistics
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStatisticsFindSummaryProject: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('controlStatisticsFindSummaryProject', 'projectId', projectId)
            const localVarPath = `/api/v1/control-statistics/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark control as completed
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsCompleteControl: async (controlId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('controlsCompleteControl', 'controlId', controlId)
            const localVarPath = `/api/v1/controls/{controlId}/complete`
                .replace(`{${"controlId"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark report as completed
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsCompleteReport: async (controlId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('controlsCompleteReport', 'controlId', controlId)
            const localVarPath = `/api/v1/controls/{controlId}/complete-report`
                .replace(`{${"controlId"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create control
         * @param {CreateControlDto} createControlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsCreate: async (createControlDto: CreateControlDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createControlDto' is not null or undefined
            assertParamExists('controlsCreate', 'createControlDto', createControlDto)
            const localVarPath = `/api/v1/controls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createControlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create control file
         * @param {string} controlId 
         * @param {CreateControlFileDto} createControlFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsCreateQuestionnaireFile: async (controlId: string, createControlFileDto: CreateControlFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('controlsCreateQuestionnaireFile', 'controlId', controlId)
            // verify required parameter 'createControlFileDto' is not null or undefined
            assertParamExists('controlsCreateQuestionnaireFile', 'createControlFileDto', createControlFileDto)
            const localVarPath = `/api/v1/controls/{controlId}/file`
                .replace(`{${"controlId"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createControlFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete control gdpr data and generate statistics table
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsDeleteGdpr: async (controlId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('controlsDeleteGdpr', 'controlId', controlId)
            const localVarPath = `/api/v1/controls/{controlId}/gdpr-data`
                .replace(`{${"controlId"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all controls
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {'clientName_ASC' | 'projectName_ASC' | 'date_ASC' | 'date_DESC' | 'inspectionId_ASC'} [sort] 
         * @param {boolean} [isCompleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsFindAll: async (take?: number, skip?: number, sort?: 'clientName_ASC' | 'projectName_ASC' | 'date_ASC' | 'date_DESC' | 'inspectionId_ASC', isCompleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/controls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (isCompleted !== undefined) {
                localVarQueryParameter['isCompleted'] = isCompleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find control by id
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsFindOne: async (controlId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('controlsFindOne', 'controlId', controlId)
            const localVarPath = `/api/v1/controls/{controlId}`
                .replace(`{${"controlId"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find control by inspection id
         * @param {string} inspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsFindOneByInspectionId: async (inspectionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inspectionId' is not null or undefined
            assertParamExists('controlsFindOneByInspectionId', 'inspectionId', inspectionId)
            const localVarPath = `/api/v1/controls/inspection/{inspectionId}`
                .replace(`{${"inspectionId"}}`, encodeURIComponent(String(inspectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find one controls files
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsFindOneFiles: async (controlId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('controlsFindOneFiles', 'controlId', controlId)
            const localVarPath = `/api/v1/controls/{controlId}/files`
                .replace(`{${"controlId"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate control report PDF
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsGenerateReport: async (controlId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('controlsGenerateReport', 'controlId', controlId)
            const localVarPath = `/api/v1/controls/{controlId}/report/pdf`
                .replace(`{${"controlId"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one control signed url
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsGetControlFile: async (controlId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('controlsGetControlFile', 'controlId', controlId)
            const localVarPath = `/api/v1/controls/controle-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (controlId !== undefined) {
                localVarQueryParameter['controlId'] = controlId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark report as started
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsStartReport: async (controlId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('controlsStartReport', 'controlId', controlId)
            const localVarPath = `/api/v1/controls/{controlId}/start-report`
                .replace(`{${"controlId"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update control
         * @param {string} controlId 
         * @param {UpdateControlDto} updateControlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsUpdate: async (controlId: string, updateControlDto: UpdateControlDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('controlsUpdate', 'controlId', controlId)
            // verify required parameter 'updateControlDto' is not null or undefined
            assertParamExists('controlsUpdate', 'updateControlDto', updateControlDto)
            const localVarPath = `/api/v1/controls/{controlId}`
                .replace(`{${"controlId"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateControlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create customer user and its related entities
         * @param {CreateCustomerUserDto} createCustomerUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUserCreate: async (createCustomerUserDto: CreateCustomerUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCustomerUserDto' is not null or undefined
            assertParamExists('customerUserCreate', 'createCustomerUserDto', createCustomerUserDto)
            const localVarPath = `/api/v1/customer-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomerUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all customer users
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUserFindAll: async (take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/customer-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all customer users on client
         * @param {string} clientId 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUserFindAllOnClient: async (clientId: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('customerUserFindAllOnClient', 'clientId', clientId)
            const localVarPath = `/api/v1/customer-users/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a deviation counter measure
         * @param {CreateDeviationCounterMeasureDto} createDeviationCounterMeasureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviationCounterMeasuresCreateOnMyControl: async (createDeviationCounterMeasureDto: CreateDeviationCounterMeasureDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeviationCounterMeasureDto' is not null or undefined
            assertParamExists('deviationCounterMeasuresCreateOnMyControl', 'createDeviationCounterMeasureDto', createDeviationCounterMeasureDto)
            const localVarPath = `/api/v1/deviation-counter-measures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeviationCounterMeasureDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete faq file
         * @param {string} faqFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqFilesDelete: async (faqFileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'faqFileId' is not null or undefined
            assertParamExists('faqFilesDelete', 'faqFileId', faqFileId)
            const localVarPath = `/api/v1/faq-files/{faqFileId}`
                .replace(`{${"faqFileId"}}`, encodeURIComponent(String(faqFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create faq
         * @param {CreateFaqDto} createFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsCreate: async (createFaqDto: CreateFaqDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFaqDto' is not null or undefined
            assertParamExists('faqsCreate', 'createFaqDto', createFaqDto)
            const localVarPath = `/api/v1/faqs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFaqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create faq file
         * @param {string} faqId 
         * @param {CreateFaqFileDto} createFaqFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsCreateFile: async (faqId: string, createFaqFileDto: CreateFaqFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'faqId' is not null or undefined
            assertParamExists('faqsCreateFile', 'faqId', faqId)
            // verify required parameter 'createFaqFileDto' is not null or undefined
            assertParamExists('faqsCreateFile', 'createFaqFileDto', createFaqFileDto)
            const localVarPath = `/api/v1/faqs/{faqId}/files`
                .replace(`{${"faqId"}}`, encodeURIComponent(String(faqId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFaqFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete faq
         * @param {string} faqId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsDelete: async (faqId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'faqId' is not null or undefined
            assertParamExists('faqsDelete', 'faqId', faqId)
            const localVarPath = `/api/v1/faqs/{faqId}`
                .replace(`{${"faqId"}}`, encodeURIComponent(String(faqId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all faqs
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsFindAll: async (search?: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/faqs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find one faq
         * @param {string} faqId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsFindOne: async (faqId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'faqId' is not null or undefined
            assertParamExists('faqsFindOne', 'faqId', faqId)
            const localVarPath = `/api/v1/faqs/{faqId}`
                .replace(`{${"faqId"}}`, encodeURIComponent(String(faqId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update faq
         * @param {string} faqId 
         * @param {UpdateFaqDto} updateFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsUpdate: async (faqId: string, updateFaqDto: UpdateFaqDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'faqId' is not null or undefined
            assertParamExists('faqsUpdate', 'faqId', faqId)
            // verify required parameter 'updateFaqDto' is not null or undefined
            assertParamExists('faqsUpdate', 'updateFaqDto', updateFaqDto)
            const localVarPath = `/api/v1/faqs/{faqId}`
                .replace(`{${"faqId"}}`, encodeURIComponent(String(faqId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFaqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload an image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesUpload: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/files/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a frame company
         * @param {CreateFrameCompanyDto} createFrameCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frameCompanyCreate: async (createFrameCompanyDto: CreateFrameCompanyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFrameCompanyDto' is not null or undefined
            assertParamExists('frameCompanyCreate', 'createFrameCompanyDto', createFrameCompanyDto)
            const localVarPath = `/api/v1/frame-company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFrameCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a frame company
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frameCompanyDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frameCompanyDelete', 'id', id)
            const localVarPath = `/api/v1/frame-company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all my frameCompanies
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frameCompanyFindAll: async (take?: number, skip?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/frame-company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all frameCompanies on client
         * @param {string} clientId 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frameCompanyFindSpecific: async (clientId: string, take?: number, skip?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('frameCompanyFindSpecific', 'clientId', clientId)
            const localVarPath = `/api/v1/frame-company/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create News
         * @param {CreateNewsDto} createNewsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsCreate: async (createNewsDto: CreateNewsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNewsDto' is not null or undefined
            assertParamExists('newsCreate', 'createNewsDto', createNewsDto)
            const localVarPath = `/api/v1/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNewsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete news
         * @param {string} newsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsDelete: async (newsId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsId' is not null or undefined
            assertParamExists('newsDelete', 'newsId', newsId)
            const localVarPath = `/api/v1/news/{newsId}`
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all news
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsFindAll: async (take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find specified news
         * @param {string} newsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsFindOne: async (newsId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsId' is not null or undefined
            assertParamExists('newsFindOne', 'newsId', newsId)
            const localVarPath = `/api/v1/news/{newsId}`
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update news
         * @param {string} newsId 
         * @param {CreateNewsDto} createNewsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsUpdate: async (newsId: string, createNewsDto: CreateNewsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsId' is not null or undefined
            assertParamExists('newsUpdate', 'newsId', newsId)
            // verify required parameter 'createNewsDto' is not null or undefined
            assertParamExists('newsUpdate', 'createNewsDto', createNewsDto)
            const localVarPath = `/api/v1/news/{newsId}`
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNewsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create personnel file
         * @param {string} personnelId 
         * @param {CreatePersonnelFileDto} createPersonnelFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelCreateFile: async (personnelId: string, createPersonnelFileDto: CreatePersonnelFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personnelId' is not null or undefined
            assertParamExists('personnelCreateFile', 'personnelId', personnelId)
            // verify required parameter 'createPersonnelFileDto' is not null or undefined
            assertParamExists('personnelCreateFile', 'createPersonnelFileDto', createPersonnelFileDto)
            const localVarPath = `/api/v1/personnel/{personnelId}/file`
                .replace(`{${"personnelId"}}`, encodeURIComponent(String(personnelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPersonnelFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary download personell
         * @param {string} workRegisterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelDownload: async (workRegisterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workRegisterId' is not null or undefined
            assertParamExists('personnelDownload', 'workRegisterId', workRegisterId)
            const localVarPath = `/api/v1/personnel/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (workRegisterId !== undefined) {
                localVarQueryParameter['workRegisterId'] = workRegisterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete personnel file
         * @param {string} personnelFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelFilesDelete: async (personnelFileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personnelFileId' is not null or undefined
            assertParamExists('personnelFilesDelete', 'personnelFileId', personnelFileId)
            const localVarPath = `/api/v1/personnel-files/{personnelFileId}`
                .replace(`{${"personnelFileId"}}`, encodeURIComponent(String(personnelFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update personnel file comment
         * @param {string} personnelFileId 
         * @param {UpdatePersonnelFileDto} updatePersonnelFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelFilesUpdateComment: async (personnelFileId: string, updatePersonnelFileDto: UpdatePersonnelFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personnelFileId' is not null or undefined
            assertParamExists('personnelFilesUpdateComment', 'personnelFileId', personnelFileId)
            // verify required parameter 'updatePersonnelFileDto' is not null or undefined
            assertParamExists('personnelFilesUpdateComment', 'updatePersonnelFileDto', updatePersonnelFileDto)
            const localVarPath = `/api/v1/personnel-files/{personnelFileId}`
                .replace(`{${"personnelFileId"}}`, encodeURIComponent(String(personnelFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePersonnelFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all personnel by work register id
         * @param {string} workRegisterId 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {'firstName_ASC' | 'firstName_DESC' | 'lastName_ASC' | 'lastName_DESC' | 'companyName_ASC' | 'companyName_DESC' | 'special' | 'todo' | 'no_safe'} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelFindAll: async (workRegisterId: string, take?: number, skip?: number, sort?: 'firstName_ASC' | 'firstName_DESC' | 'lastName_ASC' | 'lastName_DESC' | 'companyName_ASC' | 'companyName_DESC' | 'special' | 'todo' | 'no_safe', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workRegisterId' is not null or undefined
            assertParamExists('personnelFindAll', 'workRegisterId', workRegisterId)
            const localVarPath = `/api/v1/personnel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (workRegisterId !== undefined) {
                localVarQueryParameter['workRegisterId'] = workRegisterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all personnel id06 card competences
         * @param {string} personnelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelFindAllId06CardCompetences: async (personnelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personnelId' is not null or undefined
            assertParamExists('personnelFindAllId06CardCompetences', 'personnelId', personnelId)
            const localVarPath = `/api/v1/personnel/{personnelId}/id06-card/competences`
                .replace(`{${"personnelId"}}`, encodeURIComponent(String(personnelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find one personnel
         * @param {string} personnelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelFindOne: async (personnelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personnelId' is not null or undefined
            assertParamExists('personnelFindOne', 'personnelId', personnelId)
            const localVarPath = `/api/v1/personnel/{personnelId}`
                .replace(`{${"personnelId"}}`, encodeURIComponent(String(personnelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find one personnel files
         * @param {string} personnelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelFindOneFiles: async (personnelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personnelId' is not null or undefined
            assertParamExists('personnelFindOneFiles', 'personnelId', personnelId)
            const localVarPath = `/api/v1/personnel/{personnelId}/files`
                .replace(`{${"personnelId"}}`, encodeURIComponent(String(personnelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find one personnel id06 card
         * @param {string} personnelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelFindOneId06Card: async (personnelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personnelId' is not null or undefined
            assertParamExists('personnelFindOneId06Card', 'personnelId', personnelId)
            const localVarPath = `/api/v1/personnel/{personnelId}/id06-card`
                .replace(`{${"personnelId"}}`, encodeURIComponent(String(personnelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find one personnel questionnaire
         * @param {string} personnelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelFindOneQuestionnaire: async (personnelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personnelId' is not null or undefined
            assertParamExists('personnelFindOneQuestionnaire', 'personnelId', personnelId)
            const localVarPath = `/api/v1/personnel/{personnelId}/questionnaire`
                .replace(`{${"personnelId"}}`, encodeURIComponent(String(personnelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update personnel file
         * @param {string} personnelId 
         * @param {UpdateID06CardDto} updateID06CardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelUpdateID06Card: async (personnelId: string, updateID06CardDto: UpdateID06CardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personnelId' is not null or undefined
            assertParamExists('personnelUpdateID06Card', 'personnelId', personnelId)
            // verify required parameter 'updateID06CardDto' is not null or undefined
            assertParamExists('personnelUpdateID06Card', 'updateID06CardDto', updateID06CardDto)
            const localVarPath = `/api/v1/personnel/{personnelId}/id06-card`
                .replace(`{${"personnelId"}}`, encodeURIComponent(String(personnelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateID06CardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upsert personnel that has not checked in
         * @param {string} workRegisterId 
         * @param {UpsertPersonnelDto} upsertPersonnelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelUpsert: async (workRegisterId: string, upsertPersonnelDto: UpsertPersonnelDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workRegisterId' is not null or undefined
            assertParamExists('personnelUpsert', 'workRegisterId', workRegisterId)
            // verify required parameter 'upsertPersonnelDto' is not null or undefined
            assertParamExists('personnelUpsert', 'upsertPersonnelDto', upsertPersonnelDto)
            const localVarPath = `/api/v1/personnel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (workRegisterId !== undefined) {
                localVarQueryParameter['workRegisterId'] = workRegisterId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertPersonnelDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upsert personnel id06 card with external data
         * @param {string} workRegisterId 
         * @param {UpsertID06CardDto} upsertID06CardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelUpsertID06CardWithExternalData: async (workRegisterId: string, upsertID06CardDto: UpsertID06CardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workRegisterId' is not null or undefined
            assertParamExists('personnelUpsertID06CardWithExternalData', 'workRegisterId', workRegisterId)
            // verify required parameter 'upsertID06CardDto' is not null or undefined
            assertParamExists('personnelUpsertID06CardWithExternalData', 'upsertID06CardDto', upsertID06CardDto)
            const localVarPath = `/api/v1/personnel/id06-card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (workRegisterId !== undefined) {
                localVarQueryParameter['workRegisterId'] = workRegisterId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertID06CardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upsert personnel id06 card with mock data
         * @param {string} cardNumber 
         * @param {string} workRegisterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelUpsertID06CardWithMockData: async (cardNumber: string, workRegisterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardNumber' is not null or undefined
            assertParamExists('personnelUpsertID06CardWithMockData', 'cardNumber', cardNumber)
            // verify required parameter 'workRegisterId' is not null or undefined
            assertParamExists('personnelUpsertID06CardWithMockData', 'workRegisterId', workRegisterId)
            const localVarPath = `/api/v1/personnel/id06-card/mock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cardNumber !== undefined) {
                localVarQueryParameter['cardNumber'] = cardNumber;
            }

            if (workRegisterId !== undefined) {
                localVarQueryParameter['workRegisterId'] = workRegisterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upsert personnel questionnaire
         * @param {string} personnelId 
         * @param {UpsertPersonnelQuestionnaireDto} upsertPersonnelQuestionnaireDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelUpsertQuestionnaire: async (personnelId: string, upsertPersonnelQuestionnaireDto: UpsertPersonnelQuestionnaireDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personnelId' is not null or undefined
            assertParamExists('personnelUpsertQuestionnaire', 'personnelId', personnelId)
            // verify required parameter 'upsertPersonnelQuestionnaireDto' is not null or undefined
            assertParamExists('personnelUpsertQuestionnaire', 'upsertPersonnelQuestionnaireDto', upsertPersonnelQuestionnaireDto)
            const localVarPath = `/api/v1/personnel/{personnelId}/questionnaire`
                .replace(`{${"personnelId"}}`, encodeURIComponent(String(personnelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertPersonnelQuestionnaireDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all projects
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsFindAll: async (search?: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all projects related to my customer user
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {'projectName_ASC' | 'projectName_DESC' | 'mainContractor_DESC' | 'mainContractor_ASC' | 'siteManager_DESC' | 'siteManager_ASC'} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsFindAllMyProjects: async (search?: string, take?: number, skip?: number, sort?: 'projectName_ASC' | 'projectName_DESC' | 'mainContractor_DESC' | 'mainContractor_ASC' | 'siteManager_DESC' | 'siteManager_ASC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projects/my-projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all projects related with related control statistics
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsFindAllMyProjectsWithStatistics: async (search?: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projects/my-projects/with-statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a project related to my customer user
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsFindOneOfMine: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectsFindOneOfMine', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/my-projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create red listed company
         * @param {CreateRedListedCompany} createRedListedCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redListedCompaniesCreateRedListedCompany: async (createRedListedCompany: CreateRedListedCompany, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRedListedCompany' is not null or undefined
            assertParamExists('redListedCompaniesCreateRedListedCompany', 'createRedListedCompany', createRedListedCompany)
            const localVarPath = `/api/v1/red-listed-companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRedListedCompany, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all red listed companies
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {'companyName_ASC' | 'companyName_DESC' | 'registrationNumber_DESC' | 'registrationNumber_ASC' | 'date_DESC' | 'date_ASC'} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redListedCompaniesFindAllRedListedCompanies: async (search?: string, take?: number, skip?: number, sort?: 'companyName_ASC' | 'companyName_DESC' | 'registrationNumber_DESC' | 'registrationNumber_ASC' | 'date_DESC' | 'date_ASC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/red-listed-companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create control
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workRegistersCreate: async (controlId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('workRegistersCreate', 'controlId', controlId)
            const localVarPath = `/api/v1/work-registers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (controlId !== undefined) {
                localVarQueryParameter['controlId'] = controlId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create empty control
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workRegistersCreateEmptyWorkRegister: async (controlId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('workRegistersCreateEmptyWorkRegister', 'controlId', controlId)
            const localVarPath = `/api/v1/work-registers/empty`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (controlId !== undefined) {
                localVarQueryParameter['controlId'] = controlId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find control by id
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workRegistersFindOne: async (controlId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('workRegistersFindOne', 'controlId', controlId)
            const localVarPath = `/api/v1/work-registers/{controlId}`
                .replace(`{${"controlId"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create control
         * @param {CreateBackgroundControlDto} createBackgroundControlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backgroundControlsCreate(createBackgroundControlDto: CreateBackgroundControlDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backgroundControlsCreate(createBackgroundControlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create control
         * @param {CreateBackgroundControlFrameDto} createBackgroundControlFrameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backgroundControlsCreateFrame(createBackgroundControlFrameDto: CreateBackgroundControlFrameDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backgroundControlsCreateFrame(createBackgroundControlFrameDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one control signed url
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backgroundControlsGetControlFile(controlId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backgroundControlsGetControlFile(controlId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get specific background control order
         * @param {string} [companyId] 
         * @param {string} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backgroundControlsOrderGet(companyId?: string, projectId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backgroundControlsOrderGet(companyId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Order control
         * @param {CreateBackgroundControlOrderDto} createBackgroundControlOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backgroundControlsOrderOrder(createBackgroundControlOrderDto: CreateBackgroundControlOrderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backgroundControlsOrderOrder(createBackgroundControlOrderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Order control of frame company
         * @param {CreateBackgroundControlOrderFrameDto} createBackgroundControlOrderFrameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backgroundControlsOrderOrderFrame(createBackgroundControlOrderFrameDto: CreateBackgroundControlOrderFrameDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backgroundControlsOrderOrderFrame(createBackgroundControlOrderFrameDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all cities
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async citiesFindAll(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllCitiesResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.citiesFindAll(search, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get client overview statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientOverviewStatisticsFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientOverviewStatisticsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientOverviewStatisticsFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all clients
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsFindAll(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllClientsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsFindAll(search, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find specified client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsFindOne(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllClientsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsFindOne(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update client by clientId
         * @param {string} clientId 
         * @param {UpdateClientDto} updateClientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsUpdate(clientId: string, updateClientDto: UpdateClientDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsUpdate(clientId, updateClientDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all companies on my clients
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesFindAllOnMyClients(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllCompaniesResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesFindAllOnMyClients(search, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all companies on my clients
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesFindAllOnMyClientsWithStatistics(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllCompaniesResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesFindAllOnMyClientsWithStatistics(search, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find company by inspectionId
         * @param {string} inspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesFindByInspectionId(inspectionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllCompaniesResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesFindByInspectionId(inspectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update company by companyId
         * @param {string} companyId 
         * @param {UpdateCompanyDto} updateCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesUpdate(companyId: string, updateCompanyDto: UpdateCompanyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesUpdate(companyId, updateCompanyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find company statistics
         * @param {string} controlId 
         * @param {string} sort 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyStatisticsFindAll(controlId: string, sort: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllCompanyStatisticsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyStatisticsFindAll(controlId, sort, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find company statistics for companies
         * @param {FetchCompanies} fetchCompanies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyStatisticsFindCompanies(fetchCompanies: FetchCompanies, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllCompanyStatisticsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyStatisticsFindCompanies(fetchCompanies, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find company statistics average companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyStatisticsGetCompanyAverage(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyStatisticsGetCompanyAverage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete controls file
         * @param {string} controlFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlFilesDelete(controlFileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlFilesDelete(controlFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update control file comment
         * @param {string} controlFileId 
         * @param {UpdateControlFileDto} updateControlFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlFilesFindOne(controlFileId: string, updateControlFileDto: UpdateControlFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateControlFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlFilesFindOne(controlFileId, updateControlFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find control statistics
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStatisticsFindSummary(controlId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllControlStatisticsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStatisticsFindSummary(controlId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find project statistics
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStatisticsFindSummaryProject(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAllControlStatisticsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStatisticsFindSummaryProject(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark control as completed
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlsCompleteControl(controlId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateControlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlsCompleteControl(controlId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark report as completed
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlsCompleteReport(controlId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateControlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlsCompleteReport(controlId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create control
         * @param {CreateControlDto} createControlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlsCreate(createControlDto: CreateControlDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateControlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlsCreate(createControlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create control file
         * @param {string} controlId 
         * @param {CreateControlFileDto} createControlFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlsCreateQuestionnaireFile(controlId: string, createControlFileDto: CreateControlFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateControlFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlsCreateQuestionnaireFile(controlId, createControlFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete control gdpr data and generate statistics table
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlsDeleteGdpr(controlId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlsDeleteGdpr(controlId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all controls
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {'clientName_ASC' | 'projectName_ASC' | 'date_ASC' | 'date_DESC' | 'inspectionId_ASC'} [sort] 
         * @param {boolean} [isCompleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlsFindAll(take?: number, skip?: number, sort?: 'clientName_ASC' | 'projectName_ASC' | 'date_ASC' | 'date_DESC' | 'inspectionId_ASC', isCompleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllControlsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlsFindAll(take, skip, sort, isCompleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find control by id
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlsFindOne(controlId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindOneControlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlsFindOne(controlId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find control by inspection id
         * @param {string} inspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlsFindOneByInspectionId(inspectionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindOneControlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlsFindOneByInspectionId(inspectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find one controls files
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlsFindOneFiles(controlId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllControlFilesResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlsFindOneFiles(controlId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate control report PDF
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlsGenerateReport(controlId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlsGenerateReport(controlId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one control signed url
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlsGetControlFile(controlId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlsGetControlFile(controlId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark report as started
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlsStartReport(controlId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateControlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlsStartReport(controlId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update control
         * @param {string} controlId 
         * @param {UpdateControlDto} updateControlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlsUpdate(controlId: string, updateControlDto: UpdateControlDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateControlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlsUpdate(controlId, updateControlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create customer user and its related entities
         * @param {CreateCustomerUserDto} createCustomerUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerUserCreate(createCustomerUserDto: CreateCustomerUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCustomerUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerUserCreate(createCustomerUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all customer users
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerUserFindAll(take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerUserFindAll(take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all customer users on client
         * @param {string} clientId 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerUserFindAllOnClient(clientId: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerUserFindAllOnClient(clientId, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a deviation counter measure
         * @param {CreateDeviationCounterMeasureDto} createDeviationCounterMeasureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviationCounterMeasuresCreateOnMyControl(createDeviationCounterMeasureDto: CreateDeviationCounterMeasureDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviationCounterMeasure>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviationCounterMeasuresCreateOnMyControl(createDeviationCounterMeasureDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete faq file
         * @param {string} faqFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqFilesDelete(faqFileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqFilesDelete(faqFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create faq
         * @param {CreateFaqDto} createFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqsCreate(createFaqDto: CreateFaqDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateFaqResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqsCreate(createFaqDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create faq file
         * @param {string} faqId 
         * @param {CreateFaqFileDto} createFaqFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqsCreateFile(faqId: string, createFaqFileDto: CreateFaqFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateFaqFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqsCreateFile(faqId, createFaqFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete faq
         * @param {string} faqId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqsDelete(faqId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqsDelete(faqId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all faqs
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqsFindAll(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllFaqsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqsFindAll(search, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find one faq
         * @param {string} faqId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqsFindOne(faqId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindOneFaqResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqsFindOne(faqId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update faq
         * @param {string} faqId 
         * @param {UpdateFaqDto} updateFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqsUpdate(faqId: string, updateFaqDto: UpdateFaqDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateFaqResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqsUpdate(faqId, updateFaqDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload an image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesUpload(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesUpload(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a frame company
         * @param {CreateFrameCompanyDto} createFrameCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frameCompanyCreate(createFrameCompanyDto: CreateFrameCompanyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frameCompanyCreate(createFrameCompanyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a frame company
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frameCompanyDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frameCompanyDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all my frameCompanies
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frameCompanyFindAll(take?: number, skip?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllFrameCompaniesResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frameCompanyFindAll(take, skip, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all frameCompanies on client
         * @param {string} clientId 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frameCompanyFindSpecific(clientId: string, take?: number, skip?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllFrameCompaniesResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frameCompanyFindSpecific(clientId, take, skip, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create News
         * @param {CreateNewsDto} createNewsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsCreate(createNewsDto: CreateNewsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateNewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsCreate(createNewsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete news
         * @param {string} newsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsDelete(newsId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsDelete(newsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all news
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsFindAll(take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllNewsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsFindAll(take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find specified news
         * @param {string} newsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsFindOne(newsId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindOneNewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsFindOne(newsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update news
         * @param {string} newsId 
         * @param {CreateNewsDto} createNewsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsUpdate(newsId: string, createNewsDto: CreateNewsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateNewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsUpdate(newsId, createNewsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create personnel file
         * @param {string} personnelId 
         * @param {CreatePersonnelFileDto} createPersonnelFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personnelCreateFile(personnelId: string, createPersonnelFileDto: CreatePersonnelFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePersonnelFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personnelCreateFile(personnelId, createPersonnelFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary download personell
         * @param {string} workRegisterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personnelDownload(workRegisterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personnelDownload(workRegisterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete personnel file
         * @param {string} personnelFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personnelFilesDelete(personnelFileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personnelFilesDelete(personnelFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update personnel file comment
         * @param {string} personnelFileId 
         * @param {UpdatePersonnelFileDto} updatePersonnelFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personnelFilesUpdateComment(personnelFileId: string, updatePersonnelFileDto: UpdatePersonnelFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePersonnelFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personnelFilesUpdateComment(personnelFileId, updatePersonnelFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all personnel by work register id
         * @param {string} workRegisterId 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {'firstName_ASC' | 'firstName_DESC' | 'lastName_ASC' | 'lastName_DESC' | 'companyName_ASC' | 'companyName_DESC' | 'special' | 'todo' | 'no_safe'} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personnelFindAll(workRegisterId: string, take?: number, skip?: number, sort?: 'firstName_ASC' | 'firstName_DESC' | 'lastName_ASC' | 'lastName_DESC' | 'companyName_ASC' | 'companyName_DESC' | 'special' | 'todo' | 'no_safe', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllPersonnelResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personnelFindAll(workRegisterId, take, skip, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all personnel id06 card competences
         * @param {string} personnelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personnelFindAllId06CardCompetences(personnelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllID06CardCompetencesResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personnelFindAllId06CardCompetences(personnelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find one personnel
         * @param {string} personnelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personnelFindOne(personnelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindOnePersonnelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personnelFindOne(personnelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find one personnel files
         * @param {string} personnelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personnelFindOneFiles(personnelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllPersonnelFilesResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personnelFindOneFiles(personnelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find one personnel id06 card
         * @param {string} personnelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personnelFindOneId06Card(personnelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindOneID06CardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personnelFindOneId06Card(personnelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find one personnel questionnaire
         * @param {string} personnelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personnelFindOneQuestionnaire(personnelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindOnePersonnelQuestionnaireResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personnelFindOneQuestionnaire(personnelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update personnel file
         * @param {string} personnelId 
         * @param {UpdateID06CardDto} updateID06CardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personnelUpdateID06Card(personnelId: string, updateID06CardDto: UpdateID06CardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateID06CardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personnelUpdateID06Card(personnelId, updateID06CardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upsert personnel that has not checked in
         * @param {string} workRegisterId 
         * @param {UpsertPersonnelDto} upsertPersonnelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personnelUpsert(workRegisterId: string, upsertPersonnelDto: UpsertPersonnelDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpsertPersonnelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personnelUpsert(workRegisterId, upsertPersonnelDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upsert personnel id06 card with external data
         * @param {string} workRegisterId 
         * @param {UpsertID06CardDto} upsertID06CardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personnelUpsertID06CardWithExternalData(workRegisterId: string, upsertID06CardDto: UpsertID06CardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpsertID06CardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personnelUpsertID06CardWithExternalData(workRegisterId, upsertID06CardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upsert personnel id06 card with mock data
         * @param {string} cardNumber 
         * @param {string} workRegisterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personnelUpsertID06CardWithMockData(cardNumber: string, workRegisterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpsertID06CardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personnelUpsertID06CardWithMockData(cardNumber, workRegisterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upsert personnel questionnaire
         * @param {string} personnelId 
         * @param {UpsertPersonnelQuestionnaireDto} upsertPersonnelQuestionnaireDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personnelUpsertQuestionnaire(personnelId: string, upsertPersonnelQuestionnaireDto: UpsertPersonnelQuestionnaireDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpsertPersonnelQuestionnaireResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personnelUpsertQuestionnaire(personnelId, upsertPersonnelQuestionnaireDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all projects
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsFindAll(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllProjectsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsFindAll(search, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all projects related to my customer user
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {'projectName_ASC' | 'projectName_DESC' | 'mainContractor_DESC' | 'mainContractor_ASC' | 'siteManager_DESC' | 'siteManager_ASC'} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsFindAllMyProjects(search?: string, take?: number, skip?: number, sort?: 'projectName_ASC' | 'projectName_DESC' | 'mainContractor_DESC' | 'mainContractor_ASC' | 'siteManager_DESC' | 'siteManager_ASC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllMyProjectsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsFindAllMyProjects(search, take, skip, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all projects related with related control statistics
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsFindAllMyProjectsWithStatistics(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllMyProjectsWithStatisticsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsFindAllMyProjectsWithStatistics(search, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find a project related to my customer user
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsFindOneOfMine(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindMyProjectWithControls>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsFindOneOfMine(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create red listed company
         * @param {CreateRedListedCompany} createRedListedCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redListedCompaniesCreateRedListedCompany(createRedListedCompany: CreateRedListedCompany, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedListedCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redListedCompaniesCreateRedListedCompany(createRedListedCompany, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all red listed companies
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {'companyName_ASC' | 'companyName_DESC' | 'registrationNumber_DESC' | 'registrationNumber_ASC' | 'date_DESC' | 'date_ASC'} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redListedCompaniesFindAllRedListedCompanies(search?: string, take?: number, skip?: number, sort?: 'companyName_ASC' | 'companyName_DESC' | 'registrationNumber_DESC' | 'registrationNumber_ASC' | 'date_DESC' | 'date_ASC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindAllRedListedCompaniesResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redListedCompaniesFindAllRedListedCompanies(search, take, skip, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create control
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workRegistersCreate(controlId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWorkRegisterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workRegistersCreate(controlId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create empty control
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workRegistersCreateEmptyWorkRegister(controlId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWorkRegisterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workRegistersCreateEmptyWorkRegister(controlId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find control by id
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workRegistersFindOne(controlId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindOneWorkRegisterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workRegistersFindOne(controlId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appHealth(options?: any): AxiosPromise<void> {
            return localVarFp.appHealth(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create control
         * @param {CreateBackgroundControlDto} createBackgroundControlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundControlsCreate(createBackgroundControlDto: CreateBackgroundControlDto, options?: any): AxiosPromise<void> {
            return localVarFp.backgroundControlsCreate(createBackgroundControlDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create control
         * @param {CreateBackgroundControlFrameDto} createBackgroundControlFrameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundControlsCreateFrame(createBackgroundControlFrameDto: CreateBackgroundControlFrameDto, options?: any): AxiosPromise<void> {
            return localVarFp.backgroundControlsCreateFrame(createBackgroundControlFrameDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one control signed url
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundControlsGetControlFile(controlId: string, options?: any): AxiosPromise<void> {
            return localVarFp.backgroundControlsGetControlFile(controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get specific background control order
         * @param {string} [companyId] 
         * @param {string} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundControlsOrderGet(companyId?: string, projectId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.backgroundControlsOrderGet(companyId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Order control
         * @param {CreateBackgroundControlOrderDto} createBackgroundControlOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundControlsOrderOrder(createBackgroundControlOrderDto: CreateBackgroundControlOrderDto, options?: any): AxiosPromise<void> {
            return localVarFp.backgroundControlsOrderOrder(createBackgroundControlOrderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Order control of frame company
         * @param {CreateBackgroundControlOrderFrameDto} createBackgroundControlOrderFrameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backgroundControlsOrderOrderFrame(createBackgroundControlOrderFrameDto: CreateBackgroundControlOrderFrameDto, options?: any): AxiosPromise<void> {
            return localVarFp.backgroundControlsOrderOrderFrame(createBackgroundControlOrderFrameDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all cities
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        citiesFindAll(search?: string, take?: number, skip?: number, options?: any): AxiosPromise<Array<FindAllCitiesResponse>> {
            return localVarFp.citiesFindAll(search, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get client overview statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientOverviewStatisticsFindAll(options?: any): AxiosPromise<GetClientOverviewStatisticsDto> {
            return localVarFp.clientOverviewStatisticsFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all clients
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsFindAll(search?: string, take?: number, skip?: number, options?: any): AxiosPromise<Array<FindAllClientsResponse>> {
            return localVarFp.clientsFindAll(search, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find specified client
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsFindOne(clientId: string, options?: any): AxiosPromise<FindAllClientsResponse> {
            return localVarFp.clientsFindOne(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update client by clientId
         * @param {string} clientId 
         * @param {UpdateClientDto} updateClientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsUpdate(clientId: string, updateClientDto: UpdateClientDto, options?: any): AxiosPromise<Client> {
            return localVarFp.clientsUpdate(clientId, updateClientDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all companies on my clients
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesFindAllOnMyClients(search?: string, take?: number, skip?: number, options?: any): AxiosPromise<Array<FindAllCompaniesResponse>> {
            return localVarFp.companiesFindAllOnMyClients(search, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all companies on my clients
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesFindAllOnMyClientsWithStatistics(search?: string, take?: number, skip?: number, options?: any): AxiosPromise<Array<FindAllCompaniesResponse>> {
            return localVarFp.companiesFindAllOnMyClientsWithStatistics(search, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find company by inspectionId
         * @param {string} inspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesFindByInspectionId(inspectionId: string, options?: any): AxiosPromise<Array<FindAllCompaniesResponse>> {
            return localVarFp.companiesFindByInspectionId(inspectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update company by companyId
         * @param {string} companyId 
         * @param {UpdateCompanyDto} updateCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesUpdate(companyId: string, updateCompanyDto: UpdateCompanyDto, options?: any): AxiosPromise<UpdateCompanyResponse> {
            return localVarFp.companiesUpdate(companyId, updateCompanyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find company statistics
         * @param {string} controlId 
         * @param {string} sort 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyStatisticsFindAll(controlId: string, sort: string, take?: number, skip?: number, options?: any): AxiosPromise<Array<FindAllCompanyStatisticsResponse>> {
            return localVarFp.companyStatisticsFindAll(controlId, sort, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find company statistics for companies
         * @param {FetchCompanies} fetchCompanies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyStatisticsFindCompanies(fetchCompanies: FetchCompanies, options?: any): AxiosPromise<Array<FindAllCompanyStatisticsResponse>> {
            return localVarFp.companyStatisticsFindCompanies(fetchCompanies, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find company statistics average companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyStatisticsGetCompanyAverage(options?: any): AxiosPromise<void> {
            return localVarFp.companyStatisticsGetCompanyAverage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete controls file
         * @param {string} controlFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlFilesDelete(controlFileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.controlFilesDelete(controlFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update control file comment
         * @param {string} controlFileId 
         * @param {UpdateControlFileDto} updateControlFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlFilesFindOne(controlFileId: string, updateControlFileDto: UpdateControlFileDto, options?: any): AxiosPromise<UpdateControlFileResponse> {
            return localVarFp.controlFilesFindOne(controlFileId, updateControlFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find control statistics
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStatisticsFindSummary(controlId: string, options?: any): AxiosPromise<FindAllControlStatisticsResponse> {
            return localVarFp.controlStatisticsFindSummary(controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find project statistics
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStatisticsFindSummaryProject(projectId: string, options?: any): AxiosPromise<FindAllControlStatisticsResponse> {
            return localVarFp.controlStatisticsFindSummaryProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark control as completed
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsCompleteControl(controlId: string, options?: any): AxiosPromise<UpdateControlResponse> {
            return localVarFp.controlsCompleteControl(controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark report as completed
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsCompleteReport(controlId: string, options?: any): AxiosPromise<UpdateControlResponse> {
            return localVarFp.controlsCompleteReport(controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create control
         * @param {CreateControlDto} createControlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsCreate(createControlDto: CreateControlDto, options?: any): AxiosPromise<CreateControlResponse> {
            return localVarFp.controlsCreate(createControlDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create control file
         * @param {string} controlId 
         * @param {CreateControlFileDto} createControlFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsCreateQuestionnaireFile(controlId: string, createControlFileDto: CreateControlFileDto, options?: any): AxiosPromise<CreateControlFileResponse> {
            return localVarFp.controlsCreateQuestionnaireFile(controlId, createControlFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete control gdpr data and generate statistics table
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsDeleteGdpr(controlId: string, options?: any): AxiosPromise<void> {
            return localVarFp.controlsDeleteGdpr(controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all controls
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {'clientName_ASC' | 'projectName_ASC' | 'date_ASC' | 'date_DESC' | 'inspectionId_ASC'} [sort] 
         * @param {boolean} [isCompleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsFindAll(take?: number, skip?: number, sort?: 'clientName_ASC' | 'projectName_ASC' | 'date_ASC' | 'date_DESC' | 'inspectionId_ASC', isCompleted?: boolean, options?: any): AxiosPromise<Array<FindAllControlsResponse>> {
            return localVarFp.controlsFindAll(take, skip, sort, isCompleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find control by id
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsFindOne(controlId: string, options?: any): AxiosPromise<FindOneControlResponse> {
            return localVarFp.controlsFindOne(controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find control by inspection id
         * @param {string} inspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsFindOneByInspectionId(inspectionId: string, options?: any): AxiosPromise<FindOneControlResponse> {
            return localVarFp.controlsFindOneByInspectionId(inspectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find one controls files
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsFindOneFiles(controlId: string, options?: any): AxiosPromise<Array<FindAllControlFilesResponse>> {
            return localVarFp.controlsFindOneFiles(controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate control report PDF
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsGenerateReport(controlId: string, options?: any): AxiosPromise<void> {
            return localVarFp.controlsGenerateReport(controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one control signed url
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsGetControlFile(controlId: string, options?: any): AxiosPromise<void> {
            return localVarFp.controlsGetControlFile(controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark report as started
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsStartReport(controlId: string, options?: any): AxiosPromise<UpdateControlResponse> {
            return localVarFp.controlsStartReport(controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update control
         * @param {string} controlId 
         * @param {UpdateControlDto} updateControlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlsUpdate(controlId: string, updateControlDto: UpdateControlDto, options?: any): AxiosPromise<UpdateControlResponse> {
            return localVarFp.controlsUpdate(controlId, updateControlDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create customer user and its related entities
         * @param {CreateCustomerUserDto} createCustomerUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUserCreate(createCustomerUserDto: CreateCustomerUserDto, options?: any): AxiosPromise<CreateCustomerUserResponse> {
            return localVarFp.customerUserCreate(createCustomerUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all customer users
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUserFindAll(take?: number, skip?: number, options?: any): AxiosPromise<Array<CustomerUser>> {
            return localVarFp.customerUserFindAll(take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all customer users on client
         * @param {string} clientId 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUserFindAllOnClient(clientId: string, take?: number, skip?: number, options?: any): AxiosPromise<Array<CustomerUser>> {
            return localVarFp.customerUserFindAllOnClient(clientId, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a deviation counter measure
         * @param {CreateDeviationCounterMeasureDto} createDeviationCounterMeasureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviationCounterMeasuresCreateOnMyControl(createDeviationCounterMeasureDto: CreateDeviationCounterMeasureDto, options?: any): AxiosPromise<DeviationCounterMeasure> {
            return localVarFp.deviationCounterMeasuresCreateOnMyControl(createDeviationCounterMeasureDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete faq file
         * @param {string} faqFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqFilesDelete(faqFileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.faqFilesDelete(faqFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create faq
         * @param {CreateFaqDto} createFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsCreate(createFaqDto: CreateFaqDto, options?: any): AxiosPromise<CreateFaqResponse> {
            return localVarFp.faqsCreate(createFaqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create faq file
         * @param {string} faqId 
         * @param {CreateFaqFileDto} createFaqFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsCreateFile(faqId: string, createFaqFileDto: CreateFaqFileDto, options?: any): AxiosPromise<CreateFaqFileResponse> {
            return localVarFp.faqsCreateFile(faqId, createFaqFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete faq
         * @param {string} faqId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsDelete(faqId: string, options?: any): AxiosPromise<void> {
            return localVarFp.faqsDelete(faqId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all faqs
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsFindAll(search?: string, take?: number, skip?: number, options?: any): AxiosPromise<Array<FindAllFaqsResponse>> {
            return localVarFp.faqsFindAll(search, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find one faq
         * @param {string} faqId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsFindOne(faqId: string, options?: any): AxiosPromise<FindOneFaqResponse> {
            return localVarFp.faqsFindOne(faqId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update faq
         * @param {string} faqId 
         * @param {UpdateFaqDto} updateFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsUpdate(faqId: string, updateFaqDto: UpdateFaqDto, options?: any): AxiosPromise<UpdateFaqResponse> {
            return localVarFp.faqsUpdate(faqId, updateFaqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload an image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesUpload(options?: any): AxiosPromise<UploadFileResponse> {
            return localVarFp.filesUpload(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a frame company
         * @param {CreateFrameCompanyDto} createFrameCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frameCompanyCreate(createFrameCompanyDto: CreateFrameCompanyDto, options?: any): AxiosPromise<void> {
            return localVarFp.frameCompanyCreate(createFrameCompanyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a frame company
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frameCompanyDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.frameCompanyDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all my frameCompanies
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frameCompanyFindAll(take?: number, skip?: number, search?: string, options?: any): AxiosPromise<Array<FindAllFrameCompaniesResponse>> {
            return localVarFp.frameCompanyFindAll(take, skip, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all frameCompanies on client
         * @param {string} clientId 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frameCompanyFindSpecific(clientId: string, take?: number, skip?: number, search?: string, options?: any): AxiosPromise<Array<FindAllFrameCompaniesResponse>> {
            return localVarFp.frameCompanyFindSpecific(clientId, take, skip, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create News
         * @param {CreateNewsDto} createNewsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsCreate(createNewsDto: CreateNewsDto, options?: any): AxiosPromise<CreateNewsResponse> {
            return localVarFp.newsCreate(createNewsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete news
         * @param {string} newsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsDelete(newsId: string, options?: any): AxiosPromise<void> {
            return localVarFp.newsDelete(newsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all news
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsFindAll(take?: number, skip?: number, options?: any): AxiosPromise<Array<FindAllNewsResponse>> {
            return localVarFp.newsFindAll(take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find specified news
         * @param {string} newsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsFindOne(newsId: string, options?: any): AxiosPromise<FindOneNewsResponse> {
            return localVarFp.newsFindOne(newsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update news
         * @param {string} newsId 
         * @param {CreateNewsDto} createNewsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsUpdate(newsId: string, createNewsDto: CreateNewsDto, options?: any): AxiosPromise<CreateNewsResponse> {
            return localVarFp.newsUpdate(newsId, createNewsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create personnel file
         * @param {string} personnelId 
         * @param {CreatePersonnelFileDto} createPersonnelFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelCreateFile(personnelId: string, createPersonnelFileDto: CreatePersonnelFileDto, options?: any): AxiosPromise<CreatePersonnelFileResponse> {
            return localVarFp.personnelCreateFile(personnelId, createPersonnelFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary download personell
         * @param {string} workRegisterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelDownload(workRegisterId: string, options?: any): AxiosPromise<void> {
            return localVarFp.personnelDownload(workRegisterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete personnel file
         * @param {string} personnelFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelFilesDelete(personnelFileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.personnelFilesDelete(personnelFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update personnel file comment
         * @param {string} personnelFileId 
         * @param {UpdatePersonnelFileDto} updatePersonnelFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelFilesUpdateComment(personnelFileId: string, updatePersonnelFileDto: UpdatePersonnelFileDto, options?: any): AxiosPromise<UpdatePersonnelFileResponse> {
            return localVarFp.personnelFilesUpdateComment(personnelFileId, updatePersonnelFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all personnel by work register id
         * @param {string} workRegisterId 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {'firstName_ASC' | 'firstName_DESC' | 'lastName_ASC' | 'lastName_DESC' | 'companyName_ASC' | 'companyName_DESC' | 'special' | 'todo' | 'no_safe'} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelFindAll(workRegisterId: string, take?: number, skip?: number, sort?: 'firstName_ASC' | 'firstName_DESC' | 'lastName_ASC' | 'lastName_DESC' | 'companyName_ASC' | 'companyName_DESC' | 'special' | 'todo' | 'no_safe', options?: any): AxiosPromise<Array<FindAllPersonnelResponse>> {
            return localVarFp.personnelFindAll(workRegisterId, take, skip, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all personnel id06 card competences
         * @param {string} personnelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelFindAllId06CardCompetences(personnelId: string, options?: any): AxiosPromise<Array<FindAllID06CardCompetencesResponse>> {
            return localVarFp.personnelFindAllId06CardCompetences(personnelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find one personnel
         * @param {string} personnelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelFindOne(personnelId: string, options?: any): AxiosPromise<FindOnePersonnelResponse> {
            return localVarFp.personnelFindOne(personnelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find one personnel files
         * @param {string} personnelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelFindOneFiles(personnelId: string, options?: any): AxiosPromise<Array<FindAllPersonnelFilesResponse>> {
            return localVarFp.personnelFindOneFiles(personnelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find one personnel id06 card
         * @param {string} personnelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelFindOneId06Card(personnelId: string, options?: any): AxiosPromise<FindOneID06CardResponse> {
            return localVarFp.personnelFindOneId06Card(personnelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find one personnel questionnaire
         * @param {string} personnelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelFindOneQuestionnaire(personnelId: string, options?: any): AxiosPromise<FindOnePersonnelQuestionnaireResponse> {
            return localVarFp.personnelFindOneQuestionnaire(personnelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update personnel file
         * @param {string} personnelId 
         * @param {UpdateID06CardDto} updateID06CardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelUpdateID06Card(personnelId: string, updateID06CardDto: UpdateID06CardDto, options?: any): AxiosPromise<UpdateID06CardResponse> {
            return localVarFp.personnelUpdateID06Card(personnelId, updateID06CardDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upsert personnel that has not checked in
         * @param {string} workRegisterId 
         * @param {UpsertPersonnelDto} upsertPersonnelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelUpsert(workRegisterId: string, upsertPersonnelDto: UpsertPersonnelDto, options?: any): AxiosPromise<UpsertPersonnelResponse> {
            return localVarFp.personnelUpsert(workRegisterId, upsertPersonnelDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upsert personnel id06 card with external data
         * @param {string} workRegisterId 
         * @param {UpsertID06CardDto} upsertID06CardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelUpsertID06CardWithExternalData(workRegisterId: string, upsertID06CardDto: UpsertID06CardDto, options?: any): AxiosPromise<UpsertID06CardResponse> {
            return localVarFp.personnelUpsertID06CardWithExternalData(workRegisterId, upsertID06CardDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upsert personnel id06 card with mock data
         * @param {string} cardNumber 
         * @param {string} workRegisterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelUpsertID06CardWithMockData(cardNumber: string, workRegisterId: string, options?: any): AxiosPromise<UpsertID06CardResponse> {
            return localVarFp.personnelUpsertID06CardWithMockData(cardNumber, workRegisterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upsert personnel questionnaire
         * @param {string} personnelId 
         * @param {UpsertPersonnelQuestionnaireDto} upsertPersonnelQuestionnaireDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personnelUpsertQuestionnaire(personnelId: string, upsertPersonnelQuestionnaireDto: UpsertPersonnelQuestionnaireDto, options?: any): AxiosPromise<UpsertPersonnelQuestionnaireResponse> {
            return localVarFp.personnelUpsertQuestionnaire(personnelId, upsertPersonnelQuestionnaireDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all projects
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsFindAll(search?: string, take?: number, skip?: number, options?: any): AxiosPromise<Array<FindAllProjectsResponse>> {
            return localVarFp.projectsFindAll(search, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all projects related to my customer user
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {'projectName_ASC' | 'projectName_DESC' | 'mainContractor_DESC' | 'mainContractor_ASC' | 'siteManager_DESC' | 'siteManager_ASC'} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsFindAllMyProjects(search?: string, take?: number, skip?: number, sort?: 'projectName_ASC' | 'projectName_DESC' | 'mainContractor_DESC' | 'mainContractor_ASC' | 'siteManager_DESC' | 'siteManager_ASC', options?: any): AxiosPromise<Array<FindAllMyProjectsResponse>> {
            return localVarFp.projectsFindAllMyProjects(search, take, skip, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all projects related with related control statistics
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsFindAllMyProjectsWithStatistics(search?: string, take?: number, skip?: number, options?: any): AxiosPromise<Array<FindAllMyProjectsWithStatisticsResponse>> {
            return localVarFp.projectsFindAllMyProjectsWithStatistics(search, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a project related to my customer user
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsFindOneOfMine(projectId: string, options?: any): AxiosPromise<FindMyProjectWithControls> {
            return localVarFp.projectsFindOneOfMine(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create red listed company
         * @param {CreateRedListedCompany} createRedListedCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redListedCompaniesCreateRedListedCompany(createRedListedCompany: CreateRedListedCompany, options?: any): AxiosPromise<RedListedCompany> {
            return localVarFp.redListedCompaniesCreateRedListedCompany(createRedListedCompany, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all red listed companies
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {'companyName_ASC' | 'companyName_DESC' | 'registrationNumber_DESC' | 'registrationNumber_ASC' | 'date_DESC' | 'date_ASC'} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redListedCompaniesFindAllRedListedCompanies(search?: string, take?: number, skip?: number, sort?: 'companyName_ASC' | 'companyName_DESC' | 'registrationNumber_DESC' | 'registrationNumber_ASC' | 'date_DESC' | 'date_ASC', options?: any): AxiosPromise<Array<FindAllRedListedCompaniesResponse>> {
            return localVarFp.redListedCompaniesFindAllRedListedCompanies(search, take, skip, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create control
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workRegistersCreate(controlId: string, options?: any): AxiosPromise<CreateWorkRegisterResponse> {
            return localVarFp.workRegistersCreate(controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create empty control
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workRegistersCreateEmptyWorkRegister(controlId: string, options?: any): AxiosPromise<CreateWorkRegisterResponse> {
            return localVarFp.workRegistersCreateEmptyWorkRegister(controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find control by id
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workRegistersFindOne(controlId: string, options?: any): AxiosPromise<FindOneWorkRegisterResponse> {
            return localVarFp.workRegistersFindOne(controlId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appHealth(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appHealth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create control
     * @param {CreateBackgroundControlDto} createBackgroundControlDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public backgroundControlsCreate(createBackgroundControlDto: CreateBackgroundControlDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).backgroundControlsCreate(createBackgroundControlDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create control
     * @param {CreateBackgroundControlFrameDto} createBackgroundControlFrameDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public backgroundControlsCreateFrame(createBackgroundControlFrameDto: CreateBackgroundControlFrameDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).backgroundControlsCreateFrame(createBackgroundControlFrameDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one control signed url
     * @param {string} controlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public backgroundControlsGetControlFile(controlId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).backgroundControlsGetControlFile(controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get specific background control order
     * @param {string} [companyId] 
     * @param {string} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public backgroundControlsOrderGet(companyId?: string, projectId?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).backgroundControlsOrderGet(companyId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Order control
     * @param {CreateBackgroundControlOrderDto} createBackgroundControlOrderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public backgroundControlsOrderOrder(createBackgroundControlOrderDto: CreateBackgroundControlOrderDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).backgroundControlsOrderOrder(createBackgroundControlOrderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Order control of frame company
     * @param {CreateBackgroundControlOrderFrameDto} createBackgroundControlOrderFrameDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public backgroundControlsOrderOrderFrame(createBackgroundControlOrderFrameDto: CreateBackgroundControlOrderFrameDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).backgroundControlsOrderOrderFrame(createBackgroundControlOrderFrameDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all cities
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public citiesFindAll(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).citiesFindAll(search, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get client overview statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public clientOverviewStatisticsFindAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).clientOverviewStatisticsFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all clients
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public clientsFindAll(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).clientsFindAll(search, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find specified client
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public clientsFindOne(clientId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).clientsFindOne(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update client by clientId
     * @param {string} clientId 
     * @param {UpdateClientDto} updateClientDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public clientsUpdate(clientId: string, updateClientDto: UpdateClientDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).clientsUpdate(clientId, updateClientDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all companies on my clients
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companiesFindAllOnMyClients(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companiesFindAllOnMyClients(search, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all companies on my clients
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companiesFindAllOnMyClientsWithStatistics(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companiesFindAllOnMyClientsWithStatistics(search, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find company by inspectionId
     * @param {string} inspectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companiesFindByInspectionId(inspectionId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companiesFindByInspectionId(inspectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update company by companyId
     * @param {string} companyId 
     * @param {UpdateCompanyDto} updateCompanyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companiesUpdate(companyId: string, updateCompanyDto: UpdateCompanyDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companiesUpdate(companyId, updateCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find company statistics
     * @param {string} controlId 
     * @param {string} sort 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companyStatisticsFindAll(controlId: string, sort: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companyStatisticsFindAll(controlId, sort, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find company statistics for companies
     * @param {FetchCompanies} fetchCompanies 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companyStatisticsFindCompanies(fetchCompanies: FetchCompanies, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companyStatisticsFindCompanies(fetchCompanies, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find company statistics average companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companyStatisticsGetCompanyAverage(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companyStatisticsGetCompanyAverage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete controls file
     * @param {string} controlFileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlFilesDelete(controlFileId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlFilesDelete(controlFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update control file comment
     * @param {string} controlFileId 
     * @param {UpdateControlFileDto} updateControlFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlFilesFindOne(controlFileId: string, updateControlFileDto: UpdateControlFileDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlFilesFindOne(controlFileId, updateControlFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find control statistics
     * @param {string} controlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStatisticsFindSummary(controlId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStatisticsFindSummary(controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find project statistics
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStatisticsFindSummaryProject(projectId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStatisticsFindSummaryProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark control as completed
     * @param {string} controlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlsCompleteControl(controlId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlsCompleteControl(controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark report as completed
     * @param {string} controlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlsCompleteReport(controlId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlsCompleteReport(controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create control
     * @param {CreateControlDto} createControlDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlsCreate(createControlDto: CreateControlDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlsCreate(createControlDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create control file
     * @param {string} controlId 
     * @param {CreateControlFileDto} createControlFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlsCreateQuestionnaireFile(controlId: string, createControlFileDto: CreateControlFileDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlsCreateQuestionnaireFile(controlId, createControlFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete control gdpr data and generate statistics table
     * @param {string} controlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlsDeleteGdpr(controlId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlsDeleteGdpr(controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all controls
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {'clientName_ASC' | 'projectName_ASC' | 'date_ASC' | 'date_DESC' | 'inspectionId_ASC'} [sort] 
     * @param {boolean} [isCompleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlsFindAll(take?: number, skip?: number, sort?: 'clientName_ASC' | 'projectName_ASC' | 'date_ASC' | 'date_DESC' | 'inspectionId_ASC', isCompleted?: boolean, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlsFindAll(take, skip, sort, isCompleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find control by id
     * @param {string} controlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlsFindOne(controlId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlsFindOne(controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find control by inspection id
     * @param {string} inspectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlsFindOneByInspectionId(inspectionId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlsFindOneByInspectionId(inspectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find one controls files
     * @param {string} controlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlsFindOneFiles(controlId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlsFindOneFiles(controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate control report PDF
     * @param {string} controlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlsGenerateReport(controlId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlsGenerateReport(controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one control signed url
     * @param {string} controlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlsGetControlFile(controlId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlsGetControlFile(controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark report as started
     * @param {string} controlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlsStartReport(controlId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlsStartReport(controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update control
     * @param {string} controlId 
     * @param {UpdateControlDto} updateControlDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlsUpdate(controlId: string, updateControlDto: UpdateControlDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlsUpdate(controlId, updateControlDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create customer user and its related entities
     * @param {CreateCustomerUserDto} createCustomerUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customerUserCreate(createCustomerUserDto: CreateCustomerUserDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).customerUserCreate(createCustomerUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all customer users
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customerUserFindAll(take?: number, skip?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).customerUserFindAll(take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all customer users on client
     * @param {string} clientId 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public customerUserFindAllOnClient(clientId: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).customerUserFindAllOnClient(clientId, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a deviation counter measure
     * @param {CreateDeviationCounterMeasureDto} createDeviationCounterMeasureDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deviationCounterMeasuresCreateOnMyControl(createDeviationCounterMeasureDto: CreateDeviationCounterMeasureDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deviationCounterMeasuresCreateOnMyControl(createDeviationCounterMeasureDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete faq file
     * @param {string} faqFileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public faqFilesDelete(faqFileId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).faqFilesDelete(faqFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create faq
     * @param {CreateFaqDto} createFaqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public faqsCreate(createFaqDto: CreateFaqDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).faqsCreate(createFaqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create faq file
     * @param {string} faqId 
     * @param {CreateFaqFileDto} createFaqFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public faqsCreateFile(faqId: string, createFaqFileDto: CreateFaqFileDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).faqsCreateFile(faqId, createFaqFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete faq
     * @param {string} faqId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public faqsDelete(faqId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).faqsDelete(faqId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all faqs
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public faqsFindAll(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).faqsFindAll(search, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find one faq
     * @param {string} faqId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public faqsFindOne(faqId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).faqsFindOne(faqId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update faq
     * @param {string} faqId 
     * @param {UpdateFaqDto} updateFaqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public faqsUpdate(faqId: string, updateFaqDto: UpdateFaqDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).faqsUpdate(faqId, updateFaqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload an image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public filesUpload(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).filesUpload(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a frame company
     * @param {CreateFrameCompanyDto} createFrameCompanyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public frameCompanyCreate(createFrameCompanyDto: CreateFrameCompanyDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).frameCompanyCreate(createFrameCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a frame company
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public frameCompanyDelete(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).frameCompanyDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all my frameCompanies
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public frameCompanyFindAll(take?: number, skip?: number, search?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).frameCompanyFindAll(take, skip, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all frameCompanies on client
     * @param {string} clientId 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public frameCompanyFindSpecific(clientId: string, take?: number, skip?: number, search?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).frameCompanyFindSpecific(clientId, take, skip, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create News
     * @param {CreateNewsDto} createNewsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public newsCreate(createNewsDto: CreateNewsDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).newsCreate(createNewsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete news
     * @param {string} newsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public newsDelete(newsId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).newsDelete(newsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all news
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public newsFindAll(take?: number, skip?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).newsFindAll(take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find specified news
     * @param {string} newsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public newsFindOne(newsId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).newsFindOne(newsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update news
     * @param {string} newsId 
     * @param {CreateNewsDto} createNewsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public newsUpdate(newsId: string, createNewsDto: CreateNewsDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).newsUpdate(newsId, createNewsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create personnel file
     * @param {string} personnelId 
     * @param {CreatePersonnelFileDto} createPersonnelFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public personnelCreateFile(personnelId: string, createPersonnelFileDto: CreatePersonnelFileDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).personnelCreateFile(personnelId, createPersonnelFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary download personell
     * @param {string} workRegisterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public personnelDownload(workRegisterId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).personnelDownload(workRegisterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete personnel file
     * @param {string} personnelFileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public personnelFilesDelete(personnelFileId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).personnelFilesDelete(personnelFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update personnel file comment
     * @param {string} personnelFileId 
     * @param {UpdatePersonnelFileDto} updatePersonnelFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public personnelFilesUpdateComment(personnelFileId: string, updatePersonnelFileDto: UpdatePersonnelFileDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).personnelFilesUpdateComment(personnelFileId, updatePersonnelFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all personnel by work register id
     * @param {string} workRegisterId 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {'firstName_ASC' | 'firstName_DESC' | 'lastName_ASC' | 'lastName_DESC' | 'companyName_ASC' | 'companyName_DESC' | 'special' | 'todo' | 'no_safe'} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public personnelFindAll(workRegisterId: string, take?: number, skip?: number, sort?: 'firstName_ASC' | 'firstName_DESC' | 'lastName_ASC' | 'lastName_DESC' | 'companyName_ASC' | 'companyName_DESC' | 'special' | 'todo' | 'no_safe', options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).personnelFindAll(workRegisterId, take, skip, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all personnel id06 card competences
     * @param {string} personnelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public personnelFindAllId06CardCompetences(personnelId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).personnelFindAllId06CardCompetences(personnelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find one personnel
     * @param {string} personnelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public personnelFindOne(personnelId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).personnelFindOne(personnelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find one personnel files
     * @param {string} personnelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public personnelFindOneFiles(personnelId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).personnelFindOneFiles(personnelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find one personnel id06 card
     * @param {string} personnelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public personnelFindOneId06Card(personnelId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).personnelFindOneId06Card(personnelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find one personnel questionnaire
     * @param {string} personnelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public personnelFindOneQuestionnaire(personnelId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).personnelFindOneQuestionnaire(personnelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update personnel file
     * @param {string} personnelId 
     * @param {UpdateID06CardDto} updateID06CardDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public personnelUpdateID06Card(personnelId: string, updateID06CardDto: UpdateID06CardDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).personnelUpdateID06Card(personnelId, updateID06CardDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upsert personnel that has not checked in
     * @param {string} workRegisterId 
     * @param {UpsertPersonnelDto} upsertPersonnelDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public personnelUpsert(workRegisterId: string, upsertPersonnelDto: UpsertPersonnelDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).personnelUpsert(workRegisterId, upsertPersonnelDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upsert personnel id06 card with external data
     * @param {string} workRegisterId 
     * @param {UpsertID06CardDto} upsertID06CardDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public personnelUpsertID06CardWithExternalData(workRegisterId: string, upsertID06CardDto: UpsertID06CardDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).personnelUpsertID06CardWithExternalData(workRegisterId, upsertID06CardDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upsert personnel id06 card with mock data
     * @param {string} cardNumber 
     * @param {string} workRegisterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public personnelUpsertID06CardWithMockData(cardNumber: string, workRegisterId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).personnelUpsertID06CardWithMockData(cardNumber, workRegisterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upsert personnel questionnaire
     * @param {string} personnelId 
     * @param {UpsertPersonnelQuestionnaireDto} upsertPersonnelQuestionnaireDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public personnelUpsertQuestionnaire(personnelId: string, upsertPersonnelQuestionnaireDto: UpsertPersonnelQuestionnaireDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).personnelUpsertQuestionnaire(personnelId, upsertPersonnelQuestionnaireDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all projects
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsFindAll(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsFindAll(search, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all projects related to my customer user
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {'projectName_ASC' | 'projectName_DESC' | 'mainContractor_DESC' | 'mainContractor_ASC' | 'siteManager_DESC' | 'siteManager_ASC'} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsFindAllMyProjects(search?: string, take?: number, skip?: number, sort?: 'projectName_ASC' | 'projectName_DESC' | 'mainContractor_DESC' | 'mainContractor_ASC' | 'siteManager_DESC' | 'siteManager_ASC', options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsFindAllMyProjects(search, take, skip, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all projects related with related control statistics
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsFindAllMyProjectsWithStatistics(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsFindAllMyProjectsWithStatistics(search, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a project related to my customer user
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsFindOneOfMine(projectId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsFindOneOfMine(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create red listed company
     * @param {CreateRedListedCompany} createRedListedCompany 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public redListedCompaniesCreateRedListedCompany(createRedListedCompany: CreateRedListedCompany, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).redListedCompaniesCreateRedListedCompany(createRedListedCompany, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all red listed companies
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {'companyName_ASC' | 'companyName_DESC' | 'registrationNumber_DESC' | 'registrationNumber_ASC' | 'date_DESC' | 'date_ASC'} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public redListedCompaniesFindAllRedListedCompanies(search?: string, take?: number, skip?: number, sort?: 'companyName_ASC' | 'companyName_DESC' | 'registrationNumber_DESC' | 'registrationNumber_ASC' | 'date_DESC' | 'date_ASC', options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).redListedCompaniesFindAllRedListedCompanies(search, take, skip, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create control
     * @param {string} controlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public workRegistersCreate(controlId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).workRegistersCreate(controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create empty control
     * @param {string} controlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public workRegistersCreateEmptyWorkRegister(controlId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).workRegistersCreateEmptyWorkRegister(controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find control by id
     * @param {string} controlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public workRegistersFindOne(controlId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).workRegistersFindOne(controlId, options).then((request) => request(this.axios, this.basePath));
    }
}


