const all = ['workRegisters'] as const;

const findOne = (controlId: string) => {
  return [...all, controlId] as const;
};

const personnelAll = (controlId: string) => {
  return [...findOne(controlId), 'personnel'] as const;
};

const personnelFindMany = (
  controlId: string,
  filters: { page: number; sort?: string }
) => {
  return [...personnelAll(controlId), { ...filters }] as const;
};

const personnelFindOne = (controlId: string, personnelId: string) => {
  return [...personnelAll(controlId), personnelId] as const;
};

const personnelFindOneFiles = (controlId: string, personnelId: string) => {
  return [...personnelFindOne(controlId, personnelId), 'files'] as const;
};

const findOnePersonnelID06Card = (controlId: string, personnelId: string) => {
  return [...personnelFindOne(controlId, personnelId), 'id06-card'] as const;
};

const findAllPersonnelID06CardCompetences = (
  controlId: string,
  personnelId: string
) => {
  return [
    ...findOnePersonnelID06Card(controlId, personnelId),
    'competences',
  ] as const;
};

export const workRegisterKeys = {
  all,
  findOne,
  personnelAll,
  personnelFindMany,
  personnelFindOne,
  personnelFindOneFiles,
  findOnePersonnelID06Card,
  findAllPersonnelID06CardCompetences,
};
